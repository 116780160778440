import React, { Component } from "react";
import "../Calculator.css";

class KeyPadComponent extends Component {
  render() {
    return (
      <div className="calcbutton">
        <button
          className="numbutton"
          style={{ backgroundColor: "#6495ed" }}
          name="("
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          (
        </button>
        <button
          className="numbutton"
          style={{ backgroundColor: "#d98695" }}
          name="CE"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          🔙
        </button>
        <button
          className="numbutton"
          style={{ backgroundColor: "#6495ed" }}
          name=")"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          )
        </button>
        <button
          className="numbutton"
          style={{ backgroundColor: "#40e0d0" }}
          name="C"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          C
        </button>
        <br />

        <button
          className="numbutton"
          name="1"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          1
        </button>
        <button
          className="numbutton"
          name="2"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          2
        </button>
        <button
          className="numbutton"
          name="3"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          3
        </button>
        <button
          className="numbutton"
          style={{ backgroundColor: "#f4c20d" }}
          name="+"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          +
        </button>
        <br />

        <button
          className="numbutton"
          name="4"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          4
        </button>
        <button
          className="numbutton"
          name="5"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          5
        </button>
        <button
          className="numbutton"
          name="6"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          6
        </button>
        <button
          className="numbutton"
          style={{ backgroundColor: "#f4c20d" }}
          name="-"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          -
        </button>
        <br />

        <button
          className="numbutton"
          name="7"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          7
        </button>
        <button
          className="numbutton"
          name="8"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          8
        </button>
        <button
          className="numbutton"
          name="9"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          9
        </button>
        <button
          className="numbutton"
          style={{ backgroundColor: "#f4c20d" }}
          name="*"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          x
        </button>
        <br />

        <button
          className="numbutton"
          style={{ backgroundColor: "#6495ed" }}
          name="."
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          .
        </button>
        <button
          className="numbutton"
          name="0"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          0
        </button>
        <button
          className="numbutton"
          style={{ backgroundColor: "#6495ed" }}
          name="="
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          =
        </button>
        <button
          className="numbutton"
          style={{ backgroundColor: "#f4c20d" }}
          name="/"
          onClick={(e) => this.props.onClick(e.target.name)}
        >
          ÷
        </button>
        <br />
      </div>
    );
  }
}

export default KeyPadComponent;
