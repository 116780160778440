import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import Timer from "./Timer";
import "./TestPaper.css";
import Calculator from "../components/calculator/Calculator";
import Calculator1 from "../components/Calculator";
import {
  changeUserTestAnswerList,
  changeUserTestFlagList,
  changeTestTimerList,
} from "../actions/action";

class TestPaper extends React.Component {
  constructor(props) {
    super(props);
    let loggedIn = false;

    const token = localStorage.getItem("token");
    if (token) loggedIn = true;
    this.state = {
      loggedIn,
      questionList: [],
      currentQuestion: { question: "loading...", solution: "loading..." },
      submitTest: false,
      userAnswerList: [],
      userFlagList: [],
      timeLimit: [],
      answeredId: "",
      previousTime: "",
      currentTimeLimit: "",
      submitAnswerOn: true,
      imgUrl: this.props.newtestpaper.topic,
    };
  }
  componentDidMount() {
    this.List();
    this.getUserAnswers();
    this.getUserFlags();
    this.getTimeLeft();
    setInterval(() => {
      this.updateTimeLeft();
    }, 60000);
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen });

  List = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/testpaper/questions/?select_test_name=${this.props.newtestpaper.id}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => this.setState({ questionList: res.data }))
      .then(() => this.startTest());
  };

  updateOfflineData = () => {
    const answerList = this.props.usertestanswerlist || [];
    const flagList = this.props.usertestflaglist || [];
    if (answerList.length > 0) {
      answerList.forEach((item) =>
        axios
          .post(
            `https://backend.mccubeindia.com/testpaper/useranswers/`,
            item,
            {
              headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`,
              },
            }
          )
          .then(() => this.props.changeusertestanswerlist([]))
          .then(() => this.getUserAnswers)
      );
    }
    if (flagList.length > 0) {
      flagList.forEach((item) =>
        axios
          .post(`https://backend.mccubeindia.com/testpaper/flag/`, item, {
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          })
          .then(() => this.props.changeusertestflaglist([]))
          .then((res) => this.getUserFlags())
      );
    }
  };

  getUserAnswers = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/testpaper/useranswers/?user_name=${this.props.newuser}&test_name=${this.props.newtestpaper.test_name}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => this.setState({ userAnswerList: res.data }))
      .then(() => this.updateOfflineData());
  };

  getUserFlags = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/testpaper/flag/?user_name=${this.props.newuser}&test_name=${this.props.newtestpaper.test_name}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => this.setState({ userFlagList: res.data }))
      .then(() => this.updateOfflineData());
  };

  getTimeLeft = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/testpaper/timer/?user_name=${this.props.newuser}&test_name=${this.props.newtestpaper.test_name}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => this.setState({ timeLimit: res.data }))
      .then(() => this.submitTimeLimit())
      .then(() => this.previousTime())
      .then(() => this.updateTimeLeft())
      .catch((err) => console.log(err));
  };

  submitTimeLimit = () => {
    const timeleft = this.state.timeLimit
      .filter((item) => item.test_name === this.props.newtestpaper.test_name)
      .map((item) => item.time_left);
    const offlineTimeLeft = this.props.testtimerlist
      .filter((i) => i.test_name === this.props.newtestpaper.test_name)
      .map((i) => i.time_left);
    if (timeleft.length === 0) {
      const item = {
        user_name: this.props.newuser,
        test_name: this.props.newtestpaper.test_name,
        time_left: this.props.newtestpaper.time_limit_in_minutes,
      };
      axios
        .post(`https://backend.mccubeindia.com/testpaper/timer/`, item, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => this.getTimeLeft());
    } else if (offlineTimeLeft[0] < timeleft[0]) {
      const item = {
        user_name: this.props.newuser,
        test_name: this.props.newtestpaper.test_name,
        time_left: offlineTimeLeft[0],
      };
      axios
        .put(
          `https://backend.mccubeindia.com/testpaper/timer/${this.state.timeLimit[0].id}/`,
          item,
          {
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => this.getTimeLeft())
        .then(() => {
          const newTimerList = this.props.testtimerlist.filter(
            (i) => i.test_name !== this.props.newtestpaper.test_name
          );
          this.props.changetesttimerlist(newTimerList);
        });
    }
  };

  previousTime = () => {
    const timeleft = this.state.timeLimit.filter(
      (item) => item.test_name === this.props.newtestpaper.test_name
    );
    if (timeleft.length !== 0) {
      const newTime = timeleft.map((item) => item.time_left)[0];
      const offlineTimeLimit = this.props.testtimerlist
        .filter((item) => item.test_name === this.props.newtestpaper.test_name)
        .map((item) => item.time_left)[0];
      this.setState({
        previousTime:
          (offlineTimeLimit !== "") & (offlineTimeLimit < newTime)
            ? offlineTimeLimit
            : newTime,
        currentTimeLimit:
          (offlineTimeLimit !== "") & (offlineTimeLimit < newTime)
            ? offlineTimeLimit
            : newTime,
      });
    }
  };

  updateTimeLeft = () => {
    const timeleft = this.state.timeLimit;
    const timerId = timeleft[0].id || 0;
    const previousTime = this.state.previousTime;
    const newTime = previousTime - 1;
    const item = {
      user_name: this.props.newuser,
      test_name: this.props.newtestpaper.test_name,
      time_left: newTime,
    };
    axios
      .put(
        `https://backend.mccubeindia.com/testpaper/timer/${timerId}/`,
        item,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => this.setState({ previousTime: newTime }))
      .catch(() => {
        this.setState({ previousTime: newTime });
        const timerList = this.props.testtimerlist.filter(
          (i) => i.test_name !== this.props.newtestpaper.test_name
        );
        const newTimerList = [...timerList, item];
        this.props.changetesttimerlist(newTimerList);
      });
  };

  submitAnswer = (selectedOption) => {
    if (this.state.submitAnswerOn === true) {
      this.setState({ submitAnswerOn: false });
      const answered = this.state.userAnswerList.filter(
        (item) => item.question_no === this.state.currentQuestion.question_no
      );
      if (
        answered.length === 0 &&
        selectedOption === this.state.currentQuestion.correct_answer
      ) {
        const item = {
          user_name: this.props.newuser,
          test_name: this.state.currentQuestion.test_name,
          question_no: this.state.currentQuestion.question_no,
          user_answer: selectedOption,
          correct_answer: this.state.currentQuestion.correct_answer,
          marks: this.props.newtestpaper.positive_marks,
        };
        this.setState({ userAnswerList: [...this.state.userAnswerList, item] });
        axios
          .post(
            `https://backend.mccubeindia.com/testpaper/useranswers/`,
            item,
            {
              headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => this.getUserAnswers())
          .then(() => this.setState({ submitAnswerOn: true }))
          .catch(() => {
            const offlineList = this.props.usertestanswerlist || [];
            const newofflineList = [...offlineList, item];
            this.setState({ submitAnswerOn: true });
            this.props.changeusertestanswerlist(newofflineList);
          });
      } else if (
        answered.length === 0 &&
        selectedOption !== this.state.currentQuestion.correct_answer
      ) {
        const item = {
          user_name: this.props.newuser,
          test_name: this.state.currentQuestion.test_name,
          question_no: this.state.currentQuestion.question_no,
          user_answer: selectedOption,
          correct_answer: this.state.currentQuestion.correct_answer,
          marks: this.props.newtestpaper.negative_marks,
        };
        this.setState({ userAnswerList: [...this.state.userAnswerList, item] });
        axios
          .post(
            `https://backend.mccubeindia.com/testpaper/useranswers/`,
            item,
            {
              headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => this.getUserAnswers())
          .then(() => this.setState({ submitAnswerOn: true }))
          .catch(() => {
            const offlineList = this.props.usertestanswerlist || [];
            const newofflineList = [...offlineList, item];
            this.setState({ submitAnswerOn: true });
            this.props.changeusertestanswerlist(newofflineList);
          });
      } else if (selectedOption === answered[0].user_answer) {
        const ansId = answered[0].id;
        this.setState({
          userAnswerList: this.state.userAnswerList.filter(
            (i) => i.question_no !== answered[0].question_no
          ),
        });
        axios
          .delete(
            `https://backend.mccubeindia.com/testpaper/useranswers/${ansId}/`,
            {
              headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => this.getUserAnswers())
          .then(() => this.setState({ submitAnswerOn: true }))
          .catch(() => {
            const answerList = this.props.usertestanswerlist || [];
            const newofflineList = answerList.filter(
              (item) => item.question_no !== answered[0].question_no
            );
            this.setState({ submitAnswerOn: true });
            this.props.changeusertestanswerlist(newofflineList);
          });
      } else if (selectedOption === this.state.currentQuestion.correct_answer) {
        const ansId = answered[0].id;
        const item = {
          user_name: this.props.newuser,
          test_name: this.state.currentQuestion.test_name,
          question_no: this.state.currentQuestion.question_no,
          user_answer: selectedOption,
          correct_answer: this.state.currentQuestion.correct_answer,
          marks: this.props.newtestpaper.positive_marks,
        };
        const newAnsweList = this.state.userAnswerList.filter(
          (i) => i.question_no !== answered[0].question_no
        );
        this.setState({ userAnswerList: [...newAnsweList, item] });
        axios
          .put(
            `https://backend.mccubeindia.com/testpaper/useranswers/${ansId}/`,
            item,
            {
              headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => this.getUserAnswers())
          .then(() => this.setState({ submitAnswerOn: true }))
          .catch(() => {
            const answerList = this.props.usertestanswerlist || [];
            const offlineList = answerList.filter(
              (i) => i.question_no !== answered[0].question_no
            );
            const newofflineList = [...offlineList, item];
            this.setState({ submitAnswerOn: true });
            this.props.changeusertestanswerlist(newofflineList);
          });
      } else if (selectedOption !== this.state.currentQuestion.correct_answer) {
        const ansId = answered[0].id;
        const item = {
          user_name: this.props.newuser,
          test_name: this.state.currentQuestion.test_name,
          question_no: this.state.currentQuestion.question_no,
          user_answer: selectedOption,
          correct_answer: this.state.currentQuestion.correct_answer,
          marks: this.props.newtestpaper.negative_marks,
        };
        const newAnsweList = this.state.userAnswerList.filter(
          (i) => i.question_no !== answered[0].question_no
        );
        this.setState({ userAnswerList: [...newAnsweList, item] });
        axios
          .put(
            `https://backend.mccubeindia.com/testpaper/useranswers/${ansId}/`,
            item,
            {
              headers: {
                Authorization: `JWT ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => this.getUserAnswers())
          .then(() => this.setState({ submitAnswerOn: true }))
          .catch(() => {
            const answerList = this.props.usertestanswerlist || [];
            const offlineList = answerList.filter(
              (i) => i.question_no !== answered[0].question_no
            );
            const newofflineList = [...offlineList, item];
            this.setState({ submitAnswerOn: true });
            this.props.changeusertestanswerlist(newofflineList);
          });
      }
    }
  };

  submitFlag = () => {
    const answered = this.state.userFlagList.filter(
      (item) => item.question_no === this.state.currentQuestion.question_no
    );
    if (answered.length === 0) {
      const item = {
        user_name: this.props.newuser,
        test_name: this.state.currentQuestion.test_name,
        question_no: this.state.currentQuestion.question_no,
      };
      this.setState({ userFlagList: [...this.state.userFlagList, item] });
      axios
        .post(`https://backend.mccubeindia.com/testpaper/flag/`, item, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => this.getUserFlags())
        .catch(() => {
          const offlineList = this.props.usertestflaglist || [];
          const newofflineList = [...offlineList, item];
          this.props.changeusertestflaglist(newofflineList);
        });
    } else {
      const ansId = answered[0].id;

      axios
        .delete(`https://backend.mccubeindia.com/testpaper/flag/${ansId}/`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        })
        .then(() =>
          this.setState({
            userFlagList: this.state.userFlagList.filter(
              (item) => item.question_no !== answered[0].question_no
            ),
          })
        )
        .then(() => this.getUserFlags())
        .catch(() => {
          const offlineList = this.props.usertestflaglist || [];
          const newofflineList = offlineList.filter(
            (item) => item.question_no !== answered[0].question_no
          );
          this.props.changeusertestflaglist(newofflineList);
        });
    }
  };

  questionNumberList = () => {
    const flag = this.state.userFlagList.map((item) => item.question_no);
    const answered = this.state.userAnswerList.map((item) => item.question_no);
    const newItems = this.state.questionList;
    return newItems.map((item) => (
      <button
        className={
          flag.includes(item.question_no)
            ? answered.includes(item.question_no)
              ? "button1 selected-flag"
              : "button1 notselected-flag"
            : answered.includes(item.question_no)
            ? "button1 selected"
            : "button1 notselected"
        }
        onClick={() => this.selectQuestion(item)}
      >
        {item.question_no}
      </button>
    ));
  };

  startTest = () => {
    const newItems = this.state.questionList.filter(
      (item) => item.question_no === 1
    );
    alert(this.props.newtestpaper.description);
    return newItems.map((item) => this.setState({ currentQuestion: item }));
  };

  testName = () => {
    const newItems = this.state.questionList.filter(
      (item) => item.question_no === 1
    );
    return newItems.map((item) => <div>{item.test_name}</div>);
  };

  previous = (current) => {
    const previous = current - 1;
    const newItems = this.state.questionList.filter(
      (item) => item.question_no === previous
    );
    return newItems.map((item) => (
      <button className="button2" onClick={() => this.selectQuestion(item)}>
        previous
      </button>
    ));
  };

  next = (current) => {
    const next = current + 1;
    const newItems = this.state.questionList.filter(
      (item) => item.question_no === next
    );
    return newItems.map((item) => (
      <button className="button2" onClick={() => this.selectQuestion(item)}>
        next
      </button>
    ));
  };

  selectQuestion = (item) => {
    this.setState({ isOpen: false, currentQuestion: item });
  };

  submitTestPaper = () => {
    const answerSheet = this.state.userAnswerList.filter(
      (item) =>
        item.user_name === this.props.newuser &&
        item.test_name === this.state.currentQuestion.test_name
    );
    const finalScore =
      answerSheet.length !== 0
        ? answerSheet
            .map((item) => item.marks)
            .reduce((a, c) => {
              return a + c;
            })
        : 0;
    const item = {
      user_name: this.props.newuser,
      test_name: this.state.currentQuestion.test_name,
      score: finalScore,
      submitted: true,
    };
    axios
      .post(`https://backend.mccubeindia.com/testpaper/userresult/`, item, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ submitTest: true }))
      .catch((err) => console.log(err));
  };

  class_name = (x) => {
    const answered = this.state.userAnswerList.filter(
      (item) => item.question_no === this.state.currentQuestion.question_no
    );
    if (answered.length === 0) {
      return "list-group-item list-group-item-action notselectedOption";
    } else if (x === answered[0].user_answer) {
      return "list-group-item list-group-item-action selectedOption";
    } else {
      return "list-group-item list-group-item-action notselectedOption";
    }
  };

  render() {
    if (this.state.loggedIn === false) {
      return <Redirect to="/logout" />;
    } else if (this.state.submitTest === true) {
      return <Redirect to="/home" />;
    }
    return (
      <div className="topicsheet">
        {this.state.currentTimeLimit !== "" ? (
          <Timer
            time_limit={this.state.currentTimeLimit}
            submitTest={() => this.submitTestPaper()}
          />
        ) : null}
        <div>{this.testName()}</div>
        <div>{this.questionNumberList()}</div>
        <button className="button2" onClick={() => this.submitFlag()}>
          Flag
        </button>
        <div class="input-group input-group-lg">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-lg">
              Question. {this.state.currentQuestion.question_no}
            </span>
          </div>
          <div className="question">
            {this.state.currentQuestion.question.split("<br>").map((i) => (
              <p>{i}</p>
            ))}
            {this.state.currentQuestion.questionimage === "1" ? null : this
                .state.currentQuestion.questionimage === "" ? null : (
              <img
                src={
                  this.state.imgUrl + this.state.currentQuestion.questionimage
                }
                alt=""
                className="qimage"
              />
            )}
          </div>
        </div>
        <div>
          <div class="list-group">
            <button
              type="button"
              className={this.class_name("a")}
              onClick={() => this.submitAnswer("a")}
            >
              (A) {this.state.currentQuestion.option_a}
            </button>
            <button
              type="button"
              className={this.class_name("b")}
              onClick={() => this.submitAnswer("b")}
            >
              (B) {this.state.currentQuestion.option_b}
            </button>
            <button
              type="button"
              className={this.class_name("c")}
              onClick={() => this.submitAnswer("c")}
            >
              (C) {this.state.currentQuestion.option_c}
            </button>
            <button
              type="button"
              className={this.class_name("d")}
              onClick={() => this.submitAnswer("d")}
            >
              (D) {this.state.currentQuestion.option_d}
            </button>
          </div>

          <div className="bottombuttons">
            {this.previous(this.state.currentQuestion.question_no)}
            {this.next(this.state.currentQuestion.question_no)}
          </div>
        </div>
        <button className="button2" onClick={() => this.submitTestPaper()}>
          Submit
        </button>
        {this.props.newtestpaper.allow_calc ? (
          <>
            <button
              className="button2"
              onClick={() =>
                this.setState({
                  isCalcOpen: !this.state.isCalcOpen,
                  isCalc1Open: false,
                })
              }
            >
              Calculator_1
            </button>
            <button
              className="button2"
              onClick={() =>
                this.setState({
                  isCalc1Open: !this.state.isCalc1Open,
                  isCalcOpen: false,
                })
              }
            >
              Calculator_2
            </button>
          </>
        ) : null}
        {this.state.isCalcOpen ? <Calculator /> : null}
        {this.state.isCalc1Open ? <Calculator1 /> : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newuser: state.currentUser,
    newtestpaper: state.testpaper,
    usertestanswerlist: state.userTestAnswerList,
    usertestflaglist: state.userTestFlagList,
    testtimerlist: state.testTimerList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeusertestanswerlist: (userTestAnswerList) => {
      dispatch(changeUserTestAnswerList(userTestAnswerList));
    },
    changeusertestflaglist: (userTestFlagList) => {
      dispatch(changeUserTestFlagList(userTestFlagList));
    },
    changetesttimerlist: (testTimerList) => {
      dispatch(changeTestTimerList(testTimerList));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestPaper);
