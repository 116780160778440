import React, { Component } from "react";
import axios from "axios";
import "./Login.css";

export default class CurrentAffair extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentaffairs: [{ title: "", description: "", url: "", urlToImage: "" }],
    };
  }

  componentDidMount() {
    this.getData();
  }
  getData = () => {
    axios
      .get(`https://backend.mccubeindia.com/user/currentaffair/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ currentaffairs: res.data }))
      .catch(() => {
        this.props.history.push("logout");
      });
  };

  render() {
    const newItem = this.state.currentaffairs;
    return (
      <div>
        {newItem.map((item) => (
          <table class="table table-hover">
            <thead>
              <th
                style={{ color: "black", backgroundColor: "white" }}
                scope="row"
              >
                {item.title}
              </th>
            </thead>
            <tbody>
              <tr>
                <td style={{ color: "white", backgroundColor: "black" }}>
                  {item.description}
                  <a href={item.url}>detail</a>
                </td>
              </tr>
              <tr>
                <img style={{ width: "100%" }} src={item.urlToImage} alt="" />
              </tr>
            </tbody>
          </table>
        ))}
      </div>
    );
  }
}
