import React, { Component } from "react";
import axios from "axios";
import "./Login.css";
import { connect } from "react-redux";

class Syllabus extends Component {
  constructor(props) {
    super(props);
    this.state = {
      syllabus: [],
      completedTopicList: [],
      examName: "",
      subject: "",
    };
  }
  componentDidMount() {
    this.getData();
    this.completedTopicList();
  }
  getData = () => {
    axios
      .get(`https://backend.mccubeindia.com/user/syllabus/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ syllabus: res.data }))
      .catch(() => {
        this.props.history.push("logout");
      });
  };
  completedTopicList = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/sheet/index/?user_name=${this.props.newuser}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => this.setState({ completedTopicList: res.data }))
      .catch(() => {
        this.props.history.push("logout");
      });
  };
  submitTopic = (x) => {
    const item = {
      user_name: this.props.newuser,
      sheet_name: x,
    };
    axios
      .post(`https://backend.mccubeindia.com/sheet/index/`, item, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.completedTopicList())
      .catch(() => {
        this.props.history.push("logout");
      });
  };

  renderSyllabus = (x) => {
    const completedTopics = this.state.completedTopicList.map(
      (item) => item.sheet_name
    );
    const newtable = this.state.syllabus;
    const newitem = newtable.filter((item) => item.exam === x);
    const QA = newitem.filter((item) => item.subject === "QA");
    const LR = newitem.filter((item) => item.subject === "LR");
    const DI = newitem.filter((item) => item.subject === "DI");
    const VA = newitem.filter((item) => item.subject === "VA");
    const GA = newitem.filter((item) => item.subject === "GA");
    return (
      <div>
        {QA.length > 0 ? (
          <button
            className={
              this.state.subject === "QA"
                ? "subjectbuttonactive"
                : "subjectbutton"
            }
            onClick={() => this.setState({ subject: "QA" })}
          >
            QA
          </button>
        ) : null}
        {LR.length > 0 ? (
          <button
            className={
              this.state.subject === "LR"
                ? "subjectbuttonactive"
                : "subjectbutton"
            }
            onClick={() => this.setState({ subject: "LR" })}
          >
            LR
          </button>
        ) : null}
        {DI.length > 0 ? (
          <button
            className={
              this.state.subject === "DI"
                ? "subjectbuttonactive"
                : "subjectbutton"
            }
            onClick={() => this.setState({ subject: "DI" })}
          >
            DI
          </button>
        ) : null}
        {VA.length > 0 ? (
          <button
            className={
              this.state.subject === "VA"
                ? "subjectbuttonactive"
                : "subjectbutton"
            }
            onClick={() => this.setState({ subject: "VA" })}
          >
            VA
          </button>
        ) : null}
        {GA.length > 0 ? (
          <button
            className={
              this.state.subject === "GA"
                ? "subjectbuttonactive"
                : "subjectbutton"
            }
            onClick={() => this.setState({ subject: "GA" })}
          >
            GA
          </button>
        ) : null}
        {this.state.subject === "QA" ? (
          <table class="table table-hover">
            <thead>
              <th scope="row" style={{ color: "white" }}>
                Quantitative Aptitude
              </th>
              <th></th>
            </thead>
            <tbody>
              {QA.map((item) => (
                <tr>
                  <td
                    className={
                      completedTopics.includes(item.topic)
                        ? "completedtopic"
                        : null
                    }
                  >
                    {item.topic}
                  </td>
                  <td>
                    {!completedTopics.includes(item.topic) ? (
                      <button
                        className="submittopicbutton"
                        onClick={() => this.submitTopic(item.topic)}
                      >
                        Submit
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {this.state.subject === "LR" ? (
          <table class="table table-hover">
            <thead className="thead">
              <th scope="row" style={{ color: "white" }}>
                Logical Reasoning
              </th>
              <th></th>
            </thead>
            <tbody className="tbody">
              {LR.map((item) => (
                <tr>
                  <td
                    className={
                      completedTopics.includes(item.topic)
                        ? "completedtopic"
                        : null
                    }
                  >
                    {item.topic}
                  </td>
                  <td>
                    {!completedTopics.includes(item.topic) ? (
                      <button
                        className="submittopicbutton"
                        onClick={() => this.submitTopic(item.topic)}
                      >
                        Submit
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {this.state.subject === "DI" ? (
          <table class="table table-hover">
            <thead>
              <th scope="row" style={{ color: "white" }}>
                Data Interpretation
              </th>
              <th></th>
            </thead>
            <tbody>
              {DI.map((item) => (
                <tr>
                  <td
                    className={
                      completedTopics.includes(item.topic)
                        ? "completedtopic"
                        : null
                    }
                  >
                    {item.topic}
                  </td>
                  <td>
                    {!completedTopics.includes(item.topic) ? (
                      <button
                        className="submittopicbutton"
                        onClick={() => this.submitTopic(item.topic)}
                      >
                        Submit
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {this.state.subject === "VA" ? (
          <table class="table table-hover">
            <thead>
              <th scope="row" style={{ color: "white" }}>
                Verbal Ability
              </th>
              <th></th>
            </thead>
            <tbody>
              {VA.map((item) => (
                <tr>
                  <td
                    className={
                      completedTopics.includes(item.topic)
                        ? "completedtopic"
                        : null
                    }
                  >
                    {item.topic}
                  </td>
                  <td>
                    {!completedTopics.includes(item.topic) ? (
                      <button
                        className="submittopicbutton"
                        onClick={() => this.submitTopic(item.topic)}
                      >
                        Submit
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {this.state.subject === "GA" ? (
          <table class="table table-hover">
            <thead>
              <th scope="row" style={{ color: "white" }}>
                General Awareness
              </th>
              <th></th>
            </thead>
            <tbody>
              {GA.map((item) => (
                <tr>
                  <td
                    className={
                      completedTopics.includes(item.topic)
                        ? "completedtopic"
                        : null
                    }
                  >
                    {item.topic}
                  </td>
                  <td>
                    {!completedTopics.includes(item.topic) ? (
                      <button
                        className="submittopicbutton"
                        onClick={() => this.submitTopic(item.topic)}
                      >
                        Submit
                      </button>
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    );
  };
  render() {
    return (
      <div>
        <button
          className={
            this.state.examName === "CAT"
              ? "batchbuttonsactive"
              : "batchbuttons"
          }
          onClick={() => this.setState({ examName: "CAT" })}
        >
          CAT
        </button>
        <button
          className={
            this.state.examName === "CMAT"
              ? "batchbuttonsactive"
              : "batchbuttons"
          }
          onClick={() => this.setState({ examName: "CMAT" })}
        >
          CMAT
        </button>
        <button
          className={
            this.state.examName === "SNAP"
              ? "batchbuttonsactive"
              : "batchbuttons"
          }
          onClick={() => this.setState({ examName: "SNAP" })}
        >
          SNAP
        </button>
        <button
          className={
            this.state.examName === "NMAT"
              ? "batchbuttonsactive"
              : "batchbuttons"
          }
          onClick={() => this.setState({ examName: "NMAT" })}
        >
          NMAT
        </button>
        <button
          className={
            this.state.examName === "DAVV-CET"
              ? "batchbuttonsactive"
              : "batchbuttons"
          }
          onClick={() => this.setState({ examName: "DAVV-CET" })}
        >
          DAVV-CET
        </button>
        <button
          className={
            this.state.examName === "MH-CET"
              ? "batchbuttonsactive"
              : "batchbuttons"
          }
          onClick={() => this.setState({ examName: "MH-CET" })}
        >
          MH-CET
        </button>
        <button
          className={
            this.state.examName === "MAT"
              ? "batchbuttonsactive"
              : "batchbuttons"
          }
          onClick={() => this.setState({ examName: "MAT" })}
        >
          MAT
        </button>
        <button
          className={
            this.state.examName === "TISS"
              ? "batchbuttonsactive"
              : "batchbuttons"
          }
          onClick={() => this.setState({ examName: "TISS" })}
        >
          TISS
        </button>
        <button
          className={
            this.state.examName === "GMAT"
              ? "batchbuttonsactive"
              : "batchbuttons"
          }
          onClick={() => this.setState({ examName: "GMAT" })}
        >
          GMAT
        </button>
        <button
          className={
            this.state.examName === "IBSAT"
              ? "batchbuttonsactive"
              : "batchbuttons"
          }
          onClick={() => this.setState({ examName: "IBSAT" })}
        >
          IBSAT
        </button>
        <button
          className={
            this.state.examName === "ILETS"
              ? "batchbuttonsactive"
              : "batchbuttons"
          }
          onClick={() => this.setState({ examName: "ILETS" })}
        >
          IELTS
        </button>
        <button
          className={
            this.state.examName === "IPM"
              ? "batchbuttonsactive"
              : "batchbuttons"
          }
          onClick={() => this.setState({ examName: "IPM" })}
        >
          IPM
        </button>
        {this.renderSyllabus(this.state.examName)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newuser: state.currentUser,
  };
};

export default connect(mapStateToProps)(Syllabus);
