import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { changeUserAnswerList, changeUserFlagList } from "../actions/action";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import Calculator from "./Calculator";

class Quiz extends React.Component {
  constructor(props) {
    super(props);
    let loggedIn = false;

    const token = localStorage.getItem("token");
    if (token) loggedIn = true;
    this.state = {
      loggedIn,
      questionList: [],
      currentQuestion: {
        question: "loading...",
        hint: "loading...",
        option_blank: 60,
      },
      submitSheet: false,
      userAnswerList: [],
      answeredId: "",
      submitAnswerOn: true,
      currentQuestion_no: 0,
      waiting: true,
      sheetList: [],
    };
  }
  componentDidMount() {
    this.List();
    this.getUserAnswers();
    // this.activateUser();
  }

  // activateUser = () => {
  //   const item = {
  //     user_name: this.props.newuser,
  //     sheet_name: this.props.newsheet.sheet_name,
  //   };
  //   axios.post(`https://backend.mccubeindia.com/user/activeuser/`, item, {
  //     headers: {
  //       Authorization: `JWT ${localStorage.getItem("token")}`,
  //     },
  //   });
  // };

  renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      this.setState({ waiting: !this.state.waiting });
    }

    return (
      <div
        style={{
          fontFamily: "Montserrat",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ color: "#aaa" }}>Remaining</div>
        <div style={{ fontSize: 40 }}>{remainingTime}</div>
        <div style={{ color: "#aaa" }}>seconds</div>
      </div>
    );
  };
  renderTimeW = ({ remainingTime }) => {
    if (remainingTime === 0) {
      this.setState({ waiting: !this.state.waiting });
      this.startSheet();
    } else if (
      (this.state.questionList.length !== 0) &
      (this.state.currentQuestion_no === this.state.questionList.length)
    ) {
      this.props.history.push("topicsheet");
    }

    return (
      <div
        style={{
          fontFamily: "Montserrat",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ color: "#aaa" }}>Remaining</div>
        <div style={{ fontSize: 40 }}>{remainingTime}</div>
        <div style={{ color: "#aaa" }}>seconds</div>
      </div>
    );
  };
  shuffle = (x) => x.sort(() => Math.random() - 0.5);

  List = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/sheet/questions/?select_sheet_name=${this.props.newsheet.id}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        const list = res.data;
        const suffledList = this.shuffle(list);
        this.setState({ questionList: suffledList });
      });
  };

  getUserAnswers = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/sheet/useranswers/?user_name=${this.props.newuser}&sheet_name=${this.props.newsheet.sheet_name}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => this.setState({ userAnswerList: res.data }));
  };

  submitAnswer = (selectedOption) => {
    if (this.state.submitAnswerOn === true) {
      this.setState({ submitAnswerOn: false });
      const answered = this.state.userAnswerList.filter(
        (item) => item.question_no === this.state.currentQuestion.question_no
      );
      if (answered.length === 0) {
        const item = {
          user_name: this.props.newuser,
          sheet_name: this.state.currentQuestion.sheet_name,
          question_no: this.state.currentQuestion.question_no,
          user_answer: selectedOption,
          correct_answer: this.state.currentQuestion.correct_answer,
        };
        this.setState({ userAnswerList: [...this.state.userAnswerList, item] });
        axios
          .post(`https://backend.mccubeindia.com/sheet/useranswers/`, item, {
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          })
          .then(() => this.setState({ submitAnswerOn: true }))
          .then(() => this.getUserAnswers());
      } else {
        this.setState({ submitAnswerOn: true });
        alert("you cannot change your answer");
      }
    } else {
      this.setState({ submitAnswerOn: true });
    }
  };

  startSheet = () => {
    const newItem = this.state.questionList[this.state.currentQuestion_no];
    this.setState({
      currentQuestion: newItem,
      currentQuestion_no: this.state.currentQuestion_no + 1,
    });
  };

  class_name = (x) => {
    const answered = this.state.userAnswerList.filter(
      (item) => item.question_no === this.state.currentQuestion.question_no
    );
    if (answered.length === 0) {
      return "list-group-item list-group-item-action normal_option";
    } else if (x === answered[0].user_answer) {
      return "list-group-item list-group-item-action correct_option";
    } else {
      return "list-group-item list-group-item-action normal_option";
    }
  };

  render() {
    const currentDuration =
      this.props.newsheet.description !== ""
        ? Math.floor(this.props.newsheet.description) ||
          Math.floor(this.state.currentQuestion.option_blank) ||
          120
        : Math.floor(this.state.currentQuestion.option_blank) || 120;
    return this.state.waiting === false ? (
      <div className="topicsheet">
        <div class="input-group input-group-lg">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-lg">
              Question. {this.state.currentQuestion_no}
            </span>
          </div>
          <div className="question">
            {this.state.currentQuestion.question.split("<br>").map((i) => (
              <p>{i}</p>
            ))}
            {this.state.currentQuestion.question_image === "1" ? null : this
                .state.currentQuestion.question_image === "" ? null : (
              <img
                src={this.state.currentQuestion.question_image}
                alt=""
                className="qimage"
              />
            )}
          </div>
        </div>
        <div>
          <div class="list-group">
            {this.state.currentQuestion.blank_type ? (
              <button type="button" className={this.class_name("a")}>
                {this.state.currentQuestion.option_blank}
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  className={this.class_name("a")}
                  onDoubleClick={() => this.submitAnswer("a")}
                >
                  (A) {this.state.currentQuestion.option_a}
                </button>
                <button
                  type="button"
                  className={this.class_name("b")}
                  onDoubleClick={() => this.submitAnswer("b")}
                >
                  (B) {this.state.currentQuestion.option_b}
                </button>
                <button
                  type="button"
                  className={this.class_name("c")}
                  onDoubleClick={() => this.submitAnswer("c")}
                >
                  (C) {this.state.currentQuestion.option_c}
                </button>
                <button
                  type="button"
                  className={this.class_name("d")}
                  onDoubleClick={() => this.submitAnswer("d")}
                >
                  (D) {this.state.currentQuestion.option_d}
                </button>
              </div>
            )}
          </div>
          <button
            className="button2"
            onClick={() => {
              this.setState({ waiting: true });
            }}
          >
            next
          </button>
          {this.props.newsheet.allow_calc ? (
            <button
              className="button2"
              onClick={() =>
                this.setState({ isCalcOpen: !this.state.isCalcOpen })
              }
            >
              Calculator
            </button>
          ) : null}
        </div>
        <div
          style={{ display: "flex", justifyContent: "center", marginTop: 20 }}
        >
          <CountdownCircleTimer
            isPlaying
            duration={currentDuration}
            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
          >
            {this.renderTime}
          </CountdownCircleTimer>
        </div>
        {this.state.isCalcOpen ? <Calculator /> : null}
      </div>
    ) : (
      <div
        style={{ display: "flex", justifyContent: "center", marginTop: 100 }}
      >
        <CountdownCircleTimer
          isPlaying
          duration={3}
          colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
        >
          {this.renderTimeW}
        </CountdownCircleTimer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newsheet: state.sheet,
    newuser: state.currentUser,
    useranswerlist: state.userAnswerList,
    userflaglist: state.userFlagList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeuseranswerlist: (userAnswerList) => {
      dispatch(changeUserAnswerList(userAnswerList));
    },
    changeuserflaglist: (userFlagList) => {
      dispatch(changeUserFlagList(userFlagList));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Quiz);
