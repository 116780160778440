import React, { Component } from "react";
import axios from "axios";
import "./Login.css";
import { connect } from "react-redux";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import Modal from "./Modal";

class Vocab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vocab: [],
      day: this.props.day,
      number: "",
      examName: this.props.week,
      word: "",
      nword: "",
      modal: false,
      synonymsAntonyms: [],
      wordDetails: [],
      showSynonym: true,
      phonetics: "",
    };
  }
  componentDidMount() {
    this.getData();
  }
  getSynonyms = (x) => {
    axios
      .get(`https://api.dictionaryapi.dev/api/v2/entries/en/${x}`)
      .then((res) => this.setState({ wordDetails: res.data }))
      .then(() =>
        this.setState({
          showSynonym: true,
          nword: x,
          synonymsAntonyms:
            this.state.wordDetails[0].meanings[0].definitions[0].synonyms,
        })
      )
      .then(() => this.toggle());
  };
  getAntonyms = (x) => {
    axios
      .get(`https://api.dictionaryapi.dev/api/v2/entries/en/${x}`)
      .then((res) => this.setState({ wordDetails: res.data }))
      .then(() =>
        this.setState({
          showSynonym: false,
          nword: x,
          synonymsAntonyms:
            this.state.wordDetails[0].meanings[0].definitions[0].antonyms,
        })
      )
      .then(() => this.toggle());
  };
  getPronunciation = () => {
    axios
      .get(`https://api.dictionaryapi.dev/api/v2/entries/en/${this.state.word}`)
      .then((res) => this.setState({ wordDetails: res.data }))
      .then(() =>
        this.setState({
          phonetics: this.state.wordDetails[0].phonetics[0].audio,
        })
      );
  };
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  Player = () => (
    <AudioPlayer
      autoPlay
      src={`https://ssl.gstatic.com/dictionary/static/sounds/20200429/${this.state.word}--_gb_1.mp3`}
      onError={() => this.getPronunciation()}
      onEnded={() => this.setState({ word: "" })}
      controls={false}
    />
  );
  Player1 = () => (
    <AudioPlayer
      autoPlay
      src={`https:${this.state.phonetics}`}
      onEnded={() => this.setState({ word: "", phonetics: "" })}
      controls={false}
    />
  );

  getData = () => {
    axios
      .get(`https://backend.mccubeindia.com/user/vocabsheet/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ vocab: res.data }))
      .catch(() => {
        this.props.history.push("logout");
      });
  };
  renderSyllabus = (x) => {
    const newtable = this.state.vocab;
    const newitem = newtable.filter((item) => item.week === x);
    const M = newitem.filter((item) => item.day === "monday");
    const TU = newitem.filter((item) => item.day === "tuesday");
    const W = newitem.filter((item) => item.day === "wednesday");
    const TH = newitem.filter((item) => item.day === "thursday");
    const F = newitem.filter((item) => item.day === "friday");
    const SA = newitem.filter((item) => item.day === "saturday");
    const SU = newitem.filter((item) => item.day === "sunday");
    return (
      <div>
        {M.length > 0 ? (
          <button
            className={
              this.state.day === "" ? "subjectbuttonactive" : "subjectbutton"
            }
            onClick={() => this.setState({ day: "monday" })}
          >
            Monday
          </button>
        ) : null}
        {TU.length > 0 ? (
          <button
            className={
              this.state.day === "" ? "subjectbuttonactive" : "subjectbutton"
            }
            onClick={() => this.setState({ day: "tuesday" })}
          >
            Tuesday
          </button>
        ) : null}
        {W.length > 0 ? (
          <button
            className={
              this.state.day === "" ? "subjectbuttonactive" : "subjectbutton"
            }
            onClick={() => this.setState({ day: "wednesday" })}
          >
            Wednesday
          </button>
        ) : null}
        {TH.length > 0 ? (
          <button
            className={
              this.state.day === "" ? "subjectbuttonactive" : "subjectbutton"
            }
            onClick={() => this.setState({ day: "thursday" })}
          >
            Thursday
          </button>
        ) : null}
        {F.length > 0 ? (
          <button
            className={
              this.state.day === "" ? "subjectbuttonactive" : "subjectbutton"
            }
            onClick={() => this.setState({ day: "friday" })}
          >
            Friday
          </button>
        ) : null}
        {SA.length > 0 ? (
          <button
            className={
              this.state.day === "" ? "subjectbuttonactive" : "subjectbutton"
            }
            onClick={() => this.setState({ day: "saturday" })}
          >
            Saturday
          </button>
        ) : null}
        {SU.length > 0 ? (
          <button
            className={
              this.state.day === "" ? "subjectbuttonactive" : "subjectbutton"
            }
            onClick={() => this.setState({ day: "sunday" })}
          >
            Sunday
          </button>
        ) : null}
        <br />
        {this.state.day === "monday" ? (
          <table class="table table-hover">
            <thead>
              <th scope="row">Monday</th>
              <th scope="row">Word</th>
              <th scope="row">Meaning</th>
              <th scope="row">Usage</th>
            </thead>
            <tbody>
              {M.map((item) => (
                <tr>
                  <td>{item.number}</td>
                  <td>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "blue",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.setState({ word: item.word })}
                    >
                      {item.word}
                    </button>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "#40e0d0",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.getSynonyms(item.word)}
                    >
                      Synonyms
                    </button>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "#40e0d0",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.getAntonyms(item.word)}
                    >
                      Antonyms
                    </button>
                  </td>
                  <td>{item.meaning}</td>
                  <td>{item.usage}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {this.state.day === "tuesday" ? (
          <table class="table table-hover">
            <thead className="thead">
              <th scope="row">Tuesday</th>
              <th scope="row">Word</th>
              <th scope="row">Meaning</th>
              <th scope="row">Usage</th>
            </thead>
            <tbody className="tbody">
              {TU.map((item) => (
                <tr>
                  <td>{item.number}</td>
                  <td>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "blue",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.setState({ word: item.word })}
                    >
                      {item.word}
                    </button>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "#40e0d0",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.getSynonyms(item.word)}
                    >
                      Synonyms
                    </button>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "#40e0d0",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.getAntonyms(item.word)}
                    >
                      Antonyms
                    </button>
                  </td>
                  <td>{item.meaning}</td>
                  <td>{item.usage}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {this.state.day === "wednesday" ? (
          <table class="table table-hover">
            <thead>
              <th scope="row">Wednesday</th>
              <th scope="row">Word</th>
              <th scope="row">Meaning</th>
              <th scope="row">Usage</th>
            </thead>
            <tbody>
              {W.map((item) => (
                <tr>
                  <td>{item.number}</td>
                  <td>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "blue",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.setState({ word: item.word })}
                    >
                      {item.word}
                    </button>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "#40e0d0",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.getSynonyms(item.word)}
                    >
                      Synonyms
                    </button>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "#40e0d0",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.getAntonyms(item.word)}
                    >
                      Antonyms
                    </button>
                  </td>
                  <td>{item.meaning}</td>
                  <td>{item.usage}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {this.state.day === "thursday" ? (
          <table class="table table-hover">
            <thead>
              <th scope="row">Thursday</th>
              <th scope="row">Word</th>
              <th scope="row">Meaning</th>
              <th scope="row">Usage</th>
            </thead>
            <tbody>
              {TH.map((item) => (
                <tr>
                  <td>{item.number}</td>
                  <td>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "blue",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.setState({ word: item.word })}
                    >
                      {item.word}
                    </button>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "#40e0d0",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.getSynonyms(item.word)}
                    >
                      Synonyms
                    </button>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "#40e0d0",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.getAntonyms(item.word)}
                    >
                      Antonyms
                    </button>
                  </td>
                  <td>{item.meaning}</td>
                  <td>{item.usage}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {this.state.day === "friday" ? (
          <table class="table table-hover">
            <thead>
              <th scope="row">Friday</th>
              <th scope="row">Word</th>
              <th scope="row">Meaning</th>
              <th scope="row">Usage</th>
            </thead>
            <tbody>
              {F.map((item) => (
                <tr>
                  <td>{item.number}</td>
                  <td>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "blue",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.setState({ word: item.word })}
                    >
                      {item.word}
                    </button>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "#40e0d0",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.getSynonyms(item.word)}
                    >
                      Synonyms
                    </button>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "#40e0d0",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.getAntonyms(item.word)}
                    >
                      Antonyms
                    </button>
                  </td>
                  <td>{item.meaning}</td>
                  <td>{item.usage}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {this.state.day === "saturday" ? (
          <table class="table table-hover">
            <thead>
              <th scope="row">Saturday</th>
              <th scope="row">Word</th>
              <th scope="row">Meaning</th>
              <th scope="row">Usage</th>
            </thead>
            <tbody>
              {SA.map((item) => (
                <tr>
                  <td>{item.number}</td>
                  <td>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "blue",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.setState({ word: item.word })}
                    >
                      {item.word}
                    </button>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "#40e0d0",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.getSynonyms(item.word)}
                    >
                      Synonyms
                    </button>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "#40e0d0",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.getAntonyms(item.word)}
                    >
                      Antonyms
                    </button>
                  </td>
                  <td>{item.meaning}</td>
                  <td>{item.usage}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
        {this.state.day === "sunday" ? (
          <table class="table table-hover">
            <thead>
              <th scope="row">Sunday</th>
              <th scope="row">Word</th>
              <th scope="row">Meaning</th>
              <th scope="row">Usage</th>
            </thead>
            <tbody>
              {SU.map((item) => (
                <tr>
                  <td>{item.number}</td>
                  <td>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "blue",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.setState({ word: item.word })}
                    >
                      {item.word}
                    </button>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "#40e0d0",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.getSynonyms(item.word)}
                    >
                      Synonyms
                    </button>
                    <button
                      style={{
                        width: "100%",
                        borderRadius: 10,
                        backgroundColor: "#40e0d0",
                        border: "none",
                        color: "whitesmoke",
                      }}
                      onClick={() => this.getAntonyms(item.word)}
                    >
                      Antonyms
                    </button>
                  </td>
                  <td>{item.meaning}</td>
                  <td>{item.usage}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : null}
      </div>
    );
  };
  render() {
    return (
      <div>
        <button
          className={
            this.state.examName === "week1"
              ? "weekbuttonsactive"
              : "weekbuttons"
          }
          onClick={() => this.setState({ examName: "week1" })}
        >
          Week 1
        </button>
        <button
          className={
            this.state.examName === "week2"
              ? "weekbuttonsactive"
              : "weekbuttons"
          }
          onClick={() => this.setState({ examName: "week2" })}
        >
          Week 2
        </button>
        <button
          className={
            this.state.examName === "week3"
              ? "weekbuttonsactive"
              : "weekbuttons"
          }
          onClick={() => this.setState({ examName: "week3" })}
        >
          Week 3
        </button>
        <button
          className={
            this.state.examName === "week4"
              ? "weekbuttonsactive"
              : "weekbuttons"
          }
          onClick={() => this.setState({ examName: "week4" })}
        >
          Week 4
        </button>
        <button
          className={
            this.state.examName === "week5"
              ? "weekbuttonsactive"
              : "weekbuttons"
          }
          onClick={() => this.setState({ examName: "week5" })}
        >
          Week 5
        </button>
        <button
          className={
            this.state.examName === "week6"
              ? "weekbuttonsactive"
              : "weekbuttons"
          }
          onClick={() => this.setState({ examName: "week6" })}
        >
          Week 6
        </button>
        <button
          className={
            this.state.examName === "week7"
              ? "weekbuttonsactive"
              : "weekbuttons"
          }
          onClick={() => this.setState({ examName: "week7" })}
        >
          Week 7
        </button>
        <button
          className={
            this.state.examName === "week8"
              ? "weekbuttonsactive"
              : "weekbuttons"
          }
          onClick={() => this.setState({ examName: "week8" })}
        >
          Week 8
        </button>
        <button
          className={
            this.state.examName === "week9"
              ? "weekbuttonsactive"
              : "weekbuttons"
          }
          onClick={() => this.setState({ examName: "week9" })}
        >
          Week 9
        </button>
        <button
          className={
            this.state.examName === "week10"
              ? "weekbuttonsactive"
              : "weekbuttons"
          }
          onClick={() => this.setState({ examName: "week10" })}
        >
          Week 10
        </button>
        <button
          className={
            this.state.examName === "week11"
              ? "weekbuttonsactive"
              : "weekbuttons"
          }
          onClick={() => this.setState({ examName: "week11" })}
        >
          Week 11
        </button>
        <button
          className={
            this.state.examName === "week12"
              ? "weekbuttonsactive"
              : "weekbuttons"
          }
          onClick={() => this.setState({ examName: "week12" })}
        >
          Week 12
        </button>
        <button
          className={
            this.state.examName === "week13"
              ? "weekbuttonsactive"
              : "weekbuttons"
          }
          onClick={() => this.setState({ examName: "week13" })}
        >
          Week 13
        </button>
        <button
          className={
            this.state.examName === "week14"
              ? "weekbuttonsactive"
              : "weekbuttons"
          }
          onClick={() => this.setState({ examName: "week14" })}
        >
          Week 14
        </button>
        {this.renderSyllabus(this.state.examName)}
        <div style={{ display: "none" }}>
          {this.state.word !== "" ? this.Player() : null}
        </div>
        <div style={{ display: "none" }}>
          {this.state.phonetics !== "" ? this.Player1() : null}
        </div>
        {this.state.modal ? (
          <Modal
            showSynonym={this.state.showSynonym}
            word={this.state.nword}
            activeItem={this.state.synonymsAntonyms}
            toggle={this.toggle}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    day: state.day,
    week: state.week,
  };
};

export default connect(mapStateToProps)(Vocab);
