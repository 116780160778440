import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
} from "reactstrap";

export default class CustomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSynonym: this.props.showSynonym,
      word: this.props.word,
      activeItem: this.props.activeItem,
    };
  }

  render() {
    const { toggle } = this.props;
    return (
      <Modal isOpen={true} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          {" "}
          {this.state.showSynonym ? "Synonyms" : "Antonyms"} for{" "}
          {this.state.word}{" "}
        </ModalHeader>
        <ModalBody>
          <Form>
            {this.state.activeItem.map((item) => (
              <FormGroup>
                <Label for="name">{item}</Label>
              </FormGroup>
            ))}
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}
