import React, { Component } from "react";
import Sudoku from "react-sudoku-component";
import ReactPlayer from "react-player";
import { Collapse } from "reactstrap";

export default class Sudokuapp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConceptVideoOpen: false,
    };
  }
  conceptVideoToggle = () =>
    this.setState({ isConceptVideoOpen: !this.state.isConceptVideoOpen });
  render() {
    return (
      <div>
        <div className="concept_video">
          <button className="button2" onClick={() => this.conceptVideoToggle()}>
            example video
          </button>
          <Collapse isOpen={this.state.isConceptVideoOpen}>
            <div className="wrapper">
              <ReactPlayer
                controls
                url={"https://www.youtube.com/embed/FufWUtvImv4"}
                width="100%"
                height="100%"
                className="player"
              />
            </div>
          </Collapse>
        </div>
        <Sudoku />
      </div>
    );
  }
}
