import React from "react";
import { useTimer } from "react-timer-hook";

const Timer = (props) => {
  const MINUTES = props.time_limit * 60;
  const time = new Date();
  time.setSeconds(time.getSeconds() + MINUTES);

  const { seconds, minutes, hours } = useTimer({
    expiryTimestamp: time,
    onExpire: () => props.submitTest(),
  });

  return (
    <h2 className="text-center mb-3 mt-3">
      Timer: {hours}:{minutes < 10 ? "0" : null}
      {minutes}:{seconds < 10 ? "0" : null}
      {seconds}
      {(hours === 0) & (minutes === 59) & (seconds === 59)
        ? alert(`last ${minutes + 1} minutes`)
        : null}
      {(hours === 0) & (minutes === 9) & (seconds === 59)
        ? alert(`last ${minutes + 1} minutes`)
        : null}
      {(hours === 0) & (minutes === 0) & (seconds === 59)
        ? alert(`last 1 minute`)
        : null}
      {(hours === 0) & (minutes === 0) & (seconds === 10)
        ? alert(`last ${seconds} seconds`)
        : null}
      {(hours === 0) & (minutes === 0) & (seconds === 2)
        ? alert(`time up!`)
        : null}
    </h2>
  );
};

export default Timer;
