import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { HashRouter, Switch, Route } from "react-router-dom";
import Login from "./components/Login";
import Syllabus from "./components/Syllabus";
import Results from "./components/Results";
import Colleges from "./components/Colleges";
import TimeTable from "./components/TimeTable";
import TimeTableA from "./components/TimeTableA";
import Quiz from "./components/Quiz";
import Vocab from "./components/Vocab";
import CurrentAffair from "./components/CurrentAffair";
import Article from "./components/Article";
import Sudokuapp from "./components/Sudoku";
import Home from "./components/Home";
import TopicFilter from "./sheet/TopicFilter";
import TopicSheet from "./sheet/TopicSheet";
import TestFilter from "./test/TestFilter";
import CompletedSheets from "./sheet/CompletedSheets";
import CompletedTestPapers from "./test/CompletedTestPapers";
import TestPaper from "./test/TestPaper";
import TestAnalysis from "./test/TestAnalysis";
import Logout from "./components/Logout";
import CustomNavbar from "./components/Navbar";
import "./App.css";
function App() {
  return (
    <HashRouter>
      <div className="App">
        <CustomNavbar />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route path="/home" component={Home} />
          <Route path="/logout" component={Logout} />
          <Route path="/syllabus" component={Syllabus} />
          <Route path="/recenttopresults" component={Results} />
          <Route path="/topcolleges" component={Colleges} />
          <Route path="/timetable" component={TimeTable} />
          <Route path="/timetablea" component={TimeTableA} />
          <Route path="/topicfilter" component={TopicFilter} />
          <Route path="/completedsheets" component={CompletedSheets} />
          <Route path="/topicsheet" component={TopicSheet} />
          <Route path="/testfilter" component={TestFilter} />
          <Route path="/completedtestpapers" component={CompletedTestPapers} />
          <Route path="/testpaper" component={TestPaper} />
          <Route path="/testanalysis" component={TestAnalysis} />
          <Route path="/vocab" component={Vocab} />
          <Route path="/currentaffair" component={CurrentAffair} />
          <Route path="/thehinduarticle" component={Article} />
          <Route path="/sudoku" component={Sudokuapp} />
          <Route path="/quiz" component={Quiz} />
        </Switch>
      </div>
    </HashRouter>
  );
}

export default App;
