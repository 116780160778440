import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { changeSheet } from "../actions/action";
import "./TopicSheet.css";

class TopicFilter extends Component {
  constructor(props) {
    super(props);
    let loggedIn = false;

    const token = localStorage.getItem("token");
    if (token) loggedIn = true;
    this.state = {
      level: "",
      subject: "",
      topic: "",
      sheet_type: "",
      topicList: [],
      sheetList: [],
      sheetList2: [],
      completedSheetList: [],
      startsheet: false,
      loggedIn,
      filterOn: true,
      userLevel: this.props.userlevel.level,
      userFlagList: [],
      searchField: "",
    };
  }
  componentDidMount() {
    this.topicList();
    this.sheetList();
    this.completedSheetList();
    this.getUserFlags();
  }

  getUserFlags = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/sheet/flag/?user_name=${this.props.newuser}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => this.setState({ userFlagList: res.data }));
  };
  flagCount = (x) => {
    const list = this.state.userFlagList.filter(
      (item) => item.sheet_name === x
    );
    if (list.length !== 0) {
      return (
        <button
          style={{
            border: "none",
            borderRadius: "50%",
            fontSize: 10,
            backgroundColor: "red",
            color: "white",
          }}
        >
          {list.length}
        </button>
      );
    }
  };
  topicList = () => {
    axios
      .get(`https://backend.mccubeindia.com/sheet/topic/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ topicList: res.data }))
      .catch(() => {
        this.props.history.push("logout");
      });
  };

  sheetList = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/sheet/sheetname/?level=${this.state.userLevel}&subject=${this.state.subject}&topic=${this.state.topic}&sheet_type=${this.state.sheet_type}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) =>
        this.setState({
          sheetList: res.data,
          sheetList2: res.data,
          filterOn: false,
        })
      )
      .catch(() => {
        this.props.history.push("logout");
      });
  };

  completedSheetList = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/sheet/index/?user_name=${this.props.newuser}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => this.setState({ completedSheetList: res.data }))
      .catch(() => {
        this.props.history.push("logout");
      });
  };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, filterOn: true });
  };

  handleChange2 = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    const searchItem = this.state.searchField.toUpperCase();
    const List = this.state.sheetList2.filter((i) =>
      i.sheet_name.includes(searchItem)
    );
    this.setState({ sheetList: List });
  };

  renderTopics = () => {
    const subject = this.state.subject;
    const level = this.state.userLevel;
    const newItems = this.state.topicList.filter(
      (item) => (item.level === level) & (item.subject === subject)
    );
    return newItems.map((item) => <option>{item.topic}</option>);
  };

  renderSheets = () => {
    const submittedSheet = this.state.completedSheetList.map(
      (item) => item.sheet_name
    );
    const newItems = this.state.sheetList;
    return newItems.map((item) => (
      <tr>
        <th scope="row">{item.level}</th>
        <td
          className={
            submittedSheet.includes(item.sheet_name) ? "repeat" : "start"
          }
        >
          {item.subject}
        </td>
        <td
          className={
            submittedSheet.includes(item.sheet_name) ? "repeat" : "start"
          }
        >
          {item.topic} {this.flagCount(item.sheet_name)}
        </td>
        <td
          className={
            submittedSheet.includes(item.sheet_name) ? "repeat" : "start"
          }
        >
          {item.sheet_type}
        </td>
        <td
          className={
            submittedSheet.includes(item.sheet_name) ? "repeat" : "start"
          }
        >
          {submittedSheet.includes(item.sheet_name) ? (
            <button
              type="button"
              className="btn btn-outline-success repeatbutton "
              onClick={() => this.gotoSheet(item)}
            >
              Repeat
            </button>
          ) : item.published === false ? (
            <button
              type="button"
              className="btn btn-outline-success lockbutton "
            >
              Locked
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline-success startbutton"
              onClick={() => this.gotoSheet(item)}
            >
              Start
            </button>
          )}
        </td>
      </tr>
    ));
  };

  renderFilter = () => {
    return (
      <form className="listingform">
        <div className="row">
          <div className="listingform__section filtersection">
            <label
              className="listingform__label filterlabel"
              htmlFor="userLevel"
            >
              LEVEL
            </label>
            <select
              className="listingform__select filterselect"
              name="userLevel"
              onChange={this.handleChange}
              value={this.state.userLevel}
            >
              {this.props.userlevel.level === "BASIC" ? (
                <option>BASIC</option>
              ) : this.props.userlevel.level === "FOUNDATION" ? (
                <>
                  <option>BASIC</option>
                  <option>FOUNDATION</option>
                </>
              ) : this.props.userlevel.level === "MEDIUM" ? (
                <>
                  <option>BASIC</option>
                  <option>FOUNDATION</option>
                  <option>MEDIUM</option>
                </>
              ) : this.props.userlevel.level === "ADVANCED" ? (
                <>
                  <option>BASIC</option>
                  <option>FOUNDATION</option>
                  <option>MEDIUM</option>
                  <option>ADVANCED</option>
                </>
              ) : null}
            </select>
          </div>
          <div className="listingform__section filtersection">
            <label className="listingform__label filterlabel" htmlFor="subject">
              SUBJECT
            </label>
            <select
              className="listingform__select filterselect"
              name="subject"
              onChange={this.handleChange}
              value={this.state.subject}
            >
              <option></option>
              <option>QA</option>
              <option>LR</option>
              <option>DI</option>
              <option>VA</option>
              <option>GA</option>
            </select>
          </div>
          <div className="listingform__section filtersection">
            <label className="listingform__label filterlabel" htmlFor="topic">
              TOPIC
            </label>
            <select
              className="listingform__select filterselect"
              name="topic"
              onChange={this.handleChange}
              value={this.state.topic}
            >
              <option></option>
              {this.renderTopics()}
            </select>
          </div>
          <div className="listingform__section filtersection">
            <label
              className="listingform__label filterlabel"
              htmlFor="sheet_type"
            >
              SHEET TYPE
            </label>
            <select
              className="listingform__select filterselect"
              name="sheet_type"
              onChange={this.handleChange}
              value={this.state.sheet_type}
            >
              <option></option>
              <option>main</option>
              <option>practice</option>
            </select>
          </div>
          <div className="listingform__section filtersection">
            <input
              className="listingform__select filterselect"
              name="searchField"
              placeholder="search"
              onChange={this.handleChange2}
              value={this.state.searchField}
            />
          </div>
        </div>
      </form>
    );
  };
  gotoSheet = (item) => {
    this.props.changesheet(item);
    this.props.history.push("topicsheet");
  };
  render() {
    if (this.state.loggedIn === false) {
      return <Redirect to="/logout" />;
    } else
      return (
        <div>
          {this.state.filterOn ? this.sheetList() : null}
          {this.renderFilter()}

          <table class="table">
            <thead>
              <tr>
                <th scope="col">level</th>
                <th scope="col">subject</th>
                <th scope="col">topic</th>
                <th scope="col">sheet type</th>
                <th scope="col">action</th>
              </tr>
            </thead>
            <tbody>{this.renderSheets()}</tbody>
          </table>
        </div>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    newuser: state.currentUser,
    userlevel: state.currentUserLevel,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changesheet: (sheet) => {
      dispatch(changeSheet(sheet));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TopicFilter);

