import React, { Component } from "react";
import "./Login.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  changeSheet,
  changeDay,
  changeWeek,
  changeTestPaper,
} from "../actions/action";
import axios from "axios";

class TimeTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeTable: [],
      batchName: "BATCH-I",
      nextValue: 0,
      practiceSheetOn: false,
      module: 0,
      newDate: "",
      isY: true,
      currentDay: new Date().toLocaleString("en-us", { weekday: "long" }),
      autoUpdate: true,
      sheetList: [],
      testList: [],
      speedTestOn: false,
      currentSheetDetail: [],
      x: "",
      t1: "",
    };
  }

  componentDidMount() {
    this.moduleList();
    this.sheetList();
    this.testList();
  }

  sheetList = () => {
    axios
      .get("https://backend.mccubeindia.com/sheet/sheetname/", {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ sheetList: res.data }));
  };

  moduleList = () => {
    axios
      .get("https://backend.mccubeindia.com/user/timetable/", {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) =>
        this.setState({
          timeTable: res.data.filter((i) => i.level === "ADVANCE"),
        })
      );
  };

  testList = () => {
    axios
      .get("https://backend.mccubeindia.com/testpaper/testpaper/", {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ testList: res.data }));
  };

  gotoSheet = (x) => {
    const newlist = this.state.sheetList.filter(
      (item) => item.sheet_name === x
    );
    if (newlist.length !== 0) {
      if (this.state.speedTestOn === true) {
        this.state.sheetList
          .filter((item) => item.sheet_name === x)
          .map((item) => this.props.changesheet(item));
        this.props.history.push("quiz");
      } else {
        this.state.sheetList
          .filter((item) => item.sheet_name === x)
          .map((item) => this.props.changesheet(item));
        this.props.history.push("topicsheet");
      }
    } else {
      alert("locked");
    }
  };
  gotoVocab = (x, y) => {
    this.props.changeDay(x);
    this.props.changeWeek(y);
    this.props.history.push("vocab");
  };
  gotoTest = (x) => {
    this.state.testList
      .filter((item) => item.test_name === x)
      .map((item) => this.props.changeTestPaper(item));
    this.props.history.push("testpaper");
  };
  gotoQuiz = (x) => {
    const newlist = this.state.sheetList.filter(
      (item) => (item.sheet_name === x) & (item.published === true)
    );
    if (newlist.length !== 0) {
      this.state.sheetList
        .filter((item) => item.sheet_name === x)
        .map((item) => this.props.changesheet(item));
      this.props.history.push("quiz");
    } else {
      alert("locked");
    }
  };

  gotoVocabQuiz = (x, y) => {
    const z = y + x;
    const newlist = this.state.sheetList.filter(
      (item) => (item.sheet_name === z) & (item.published === true)
    );
    if (newlist.length !== 0) {
      this.state.sheetList
        .filter((item) => item.sheet_name === z)
        .map((item) => this.props.changesheet(item));
      this.props.history.push("quiz");
    } else {
      alert("locked");
    }
  };

  renderSheetName = (y, z) => {
    const currentDay = this.state.currentDay;
    const thisDate = new Date();
    const thisHour = thisDate.getHours();
    const thisMinute = thisDate.getMinutes();
    const timeVaue = thisHour * 100 + thisMinute;
    const moduleId = this.state.isY ? y : z;
    const newtable = this.state.timeTable.filter(
      (item) => item.serial_no === moduleId
    );
    if (currentDay === "Monday") {
      if (
        (timeVaue >= 845) & (timeVaue < 915) ||
        (timeVaue >= 1145) & (timeVaue < 1215) ||
        (timeVaue >= 1445) & (timeVaue < 1515) ||
        (timeVaue >= 1745) & (timeVaue < 1815)
      ) {
        const sheet = newtable.map((i) => i.mvtopic)[0];
        this.props.changeDay("monday");
        this.props.changeWeek(sheet);
        this.props.history.push("vocab");
      } else if (
        (timeVaue >= 915) & (timeVaue < 1030) ||
        (timeVaue >= 1215) & (timeVaue < 1330) ||
        (timeVaue >= 1515) & (timeVaue < 1630) ||
        (timeVaue >= 1815) & (timeVaue < 1930)
      ) {
        const sheet = newtable.map((i) => i.ml1sheetm)[0];
        this.state.sheetList
          .filter((item) => item.sheet_name === sheet)
          .map((item) => this.props.changesheet(item));
        this.props.history.push("topicsheet");
      } else if (
        (timeVaue >= 1030) & (timeVaue < 1100) ||
        (timeVaue >= 1330) & (timeVaue < 1400) ||
        (timeVaue >= 1630) & (timeVaue < 1700) ||
        (timeVaue >= 1930) & (timeVaue < 2000)
      ) {
        const sheet = newtable.map((i) => i.ml2sheetm)[0];
        this.state.sheetList
          .filter((item) => item.sheet_name === sheet)
          .map((item) => this.props.changesheet(item));
        this.props.history.push("topicsheet");
      } else if (
        (timeVaue >= 1100) & (timeVaue < 1115) ||
        (timeVaue >= 1400) & (timeVaue < 1415) ||
        (timeVaue >= 1700) & (timeVaue < 1715) ||
        (timeVaue >= 2000) & (timeVaue < 2015)
      ) {
        const sheet = newtable.map((i) => i.mvtopic)[0];
        this.gotoVocabQuiz("monday", sheet);
      }
    }
    if (currentDay === "Tuesday") {
      if (
        (timeVaue >= 845) & (timeVaue < 915) ||
        (timeVaue >= 1145) & (timeVaue < 1215) ||
        (timeVaue >= 1445) & (timeVaue < 1515) ||
        (timeVaue >= 1745) & (timeVaue < 1815)
      ) {
        const sheet = newtable.map((i) => i.mvtopic)[0];
        this.props.changeDay("tuesday");
        this.props.changeWeek(sheet);
        this.props.history.push("vocab");
      } else if (
        (timeVaue >= 915) & (timeVaue < 1030) ||
        (timeVaue >= 1215) & (timeVaue < 1330) ||
        (timeVaue >= 1515) & (timeVaue < 1630) ||
        (timeVaue >= 1815) & (timeVaue < 1930)
      ) {
        const sheet = newtable.map((i) => i.tul1sheetm)[0];
        this.state.sheetList
          .filter((item) => item.sheet_name === sheet)
          .map((item) => this.props.changesheet(item));
        this.props.history.push("topicsheet");
      } else if (
        (timeVaue >= 1030) & (timeVaue < 1100) ||
        (timeVaue >= 1330) & (timeVaue < 1400) ||
        (timeVaue >= 1630) & (timeVaue < 1700) ||
        (timeVaue >= 1930) & (timeVaue < 2000)
      ) {
        const sheet = newtable.map((i) => i.tul2sheetm)[0];
        this.state.sheetList
          .filter((item) => item.sheet_name === sheet)
          .map((item) => this.props.changesheet(item));
        this.props.history.push("topicsheet");
      } else if (
        (timeVaue >= 1100) & (timeVaue < 1115) ||
        (timeVaue >= 1400) & (timeVaue < 1415) ||
        (timeVaue >= 1700) & (timeVaue < 1715) ||
        (timeVaue >= 2000) & (timeVaue < 2015)
      ) {
        const sheet = newtable.map((i) => i.mvtopic)[0];
        this.gotoVocabQuiz("tuesday", sheet);
      }
    }
    if (currentDay === "Wednesday") {
      if (
        (timeVaue >= 845) & (timeVaue < 915) ||
        (timeVaue >= 1145) & (timeVaue < 1215) ||
        (timeVaue >= 1445) & (timeVaue < 1515) ||
        (timeVaue >= 1745) & (timeVaue < 1815)
      ) {
        const sheet = newtable.map((i) => i.mvtopic)[0];
        this.props.changeDay("wednesday");
        this.props.changeWeek(sheet);
        this.props.history.push("vocab");
      } else if (
        (timeVaue >= 915) & (timeVaue < 1030) ||
        (timeVaue >= 1215) & (timeVaue < 1330) ||
        (timeVaue >= 1515) & (timeVaue < 1630) ||
        (timeVaue >= 1815) & (timeVaue < 1930)
      ) {
        const sheet = newtable.map((i) => i.wl1sheetm)[0];
        this.state.sheetList
          .filter((item) => item.sheet_name === sheet)
          .map((item) => this.props.changesheet(item));
        this.props.history.push("topicsheet");
      } else if (
        (timeVaue >= 1030) & (timeVaue < 1100) ||
        (timeVaue >= 1330) & (timeVaue < 1400) ||
        (timeVaue >= 1630) & (timeVaue < 1700) ||
        (timeVaue >= 1930) & (timeVaue < 2000)
      ) {
        const sheet = newtable.map((i) => i.wl2sheetm)[0];
        this.state.sheetList
          .filter((item) => item.sheet_name === sheet)
          .map((item) => this.props.changesheet(item));
        this.props.history.push("topicsheet");
      } else if (
        (timeVaue >= 1100) & (timeVaue < 1115) ||
        (timeVaue >= 1400) & (timeVaue < 1415) ||
        (timeVaue >= 1700) & (timeVaue < 1715) ||
        (timeVaue >= 2000) & (timeVaue < 2015)
      ) {
        const sheet = newtable.map((i) => i.mvtopic)[0];
        this.gotoVocabQuiz("wednesday", sheet);
      }
    }
    if (currentDay === "thursday") {
      if (
        (timeVaue >= 845) & (timeVaue < 915) ||
        (timeVaue >= 1145) & (timeVaue < 1215) ||
        (timeVaue >= 1445) & (timeVaue < 1515) ||
        (timeVaue >= 1745) & (timeVaue < 1815)
      ) {
        const sheet = newtable.map((i) => i.mvtopic)[0];
        this.props.changeDay("thursday");
        this.props.changeWeek(sheet);
        this.props.history.push("vocab");
      } else if (
        (timeVaue >= 915) & (timeVaue < 1030) ||
        (timeVaue >= 1215) & (timeVaue < 1330) ||
        (timeVaue >= 1515) & (timeVaue < 1630) ||
        (timeVaue >= 1815) & (timeVaue < 1930)
      ) {
        const sheet = newtable.map((i) => i.thl1sheetm)[0];
        this.state.sheetList
          .filter((item) => item.sheet_name === sheet)
          .map((item) => this.props.changesheet(item));
        this.props.history.push("topicsheet");
      } else if (
        (timeVaue >= 1030) & (timeVaue < 1100) ||
        (timeVaue >= 1330) & (timeVaue < 1400) ||
        (timeVaue >= 1630) & (timeVaue < 1700) ||
        (timeVaue >= 1930) & (timeVaue < 2000)
      ) {
        const sheet = newtable.map((i) => i.thl2sheetm)[0];
        this.state.sheetList
          .filter((item) => item.sheet_name === sheet)
          .map((item) => this.props.changesheet(item));
        this.props.history.push("topicsheet");
      } else if (
        (timeVaue >= 1100) & (timeVaue < 1115) ||
        (timeVaue >= 1400) & (timeVaue < 1415) ||
        (timeVaue >= 1700) & (timeVaue < 1715) ||
        (timeVaue >= 2000) & (timeVaue < 2015)
      ) {
        const sheet = newtable.map((i) => i.mvtopic)[0];
        this.gotoVocabQuiz("thursday", sheet);
      }
    }
    if (currentDay === "Friday") {
      if (
        (timeVaue >= 845) & (timeVaue < 915) ||
        (timeVaue >= 1145) & (timeVaue < 1215) ||
        (timeVaue >= 1445) & (timeVaue < 1515) ||
        (timeVaue >= 1745) & (timeVaue < 1815)
      ) {
        const sheet = newtable.map((i) => i.mvtopic)[0];
        this.props.changeDay("friday");
        this.props.changeWeek(sheet);
        this.props.history.push("vocab");
      } else if (
        (timeVaue >= 915) & (timeVaue < 1030) ||
        (timeVaue >= 1215) & (timeVaue < 1330) ||
        (timeVaue >= 1515) & (timeVaue < 1630) ||
        (timeVaue >= 1815) & (timeVaue < 1930)
      ) {
        const sheet = newtable.map((i) => i.fl1sheetm)[0];
        this.state.sheetList
          .filter((item) => item.sheet_name === sheet)
          .map((item) => this.props.changesheet(item));
        this.props.history.push("topicsheet");
      } else if (
        (timeVaue >= 1030) & (timeVaue < 1100) ||
        (timeVaue >= 1330) & (timeVaue < 1400) ||
        (timeVaue >= 1630) & (timeVaue < 1700) ||
        (timeVaue >= 1930) & (timeVaue < 2000)
      ) {
        const sheet = newtable.map((i) => i.fl2sheetm)[0];
        this.state.sheetList
          .filter((item) => item.sheet_name === sheet)
          .map((item) => this.props.changesheet(item));
        this.props.history.push("topicsheet");
      } else if (
        (timeVaue >= 1100) & (timeVaue < 1115) ||
        (timeVaue >= 1400) & (timeVaue < 1415) ||
        (timeVaue >= 1700) & (timeVaue < 1715) ||
        (timeVaue >= 2000) & (timeVaue < 2015)
      ) {
        const sheet = newtable.map((i) => i.mvtopic)[0];
        this.gotoVocabQuiz("friday", sheet);
      }
    }
  };

  renderTable = (y, z, finalDate) => {
    const moduleId = this.state.isY ? y : z;
    const newmoduleId = moduleId % 6 === 0 ? 6 : moduleId;
    const newtable = this.state.timeTable.filter(
      (item) => item.serial_no === newmoduleId
    );
    return newtable.map((item) => (
      <div>
        <table class="table table-hover">
          <tbody>
            <tr>
              <th scope="col">
                {this.state.nextValue !== 0 ? (
                  <button
                    onClick={() =>
                      this.setState({ nextValue: this.state.nextValue - 1 })
                    }
                    style={{ border: "none", borderRadius: "50%" }}
                  >
                    <FontAwesomeIcon icon={faAngleDoubleLeft} />
                  </button>
                ) : (
                  " "
                )}
                {"    "}
                Start Date:- {finalDate}
                {"    "}
                <button
                  onClick={() => this.setState({ practiceSheetOn: false })}
                  style={{
                    backgroundColor: !this.state.practiceSheetOn
                      ? "#40e0d0"
                      : "",
                    border: "none",
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                  }}
                >
                  main
                </button>
                <button
                  onClick={() => this.setState({ practiceSheetOn: true })}
                  style={{
                    backgroundColor: this.state.practiceSheetOn
                      ? "#40e0d0"
                      : "",
                    border: "none",
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                >
                  practice
                </button>
                {"    "}
                <button
                  onClick={() =>
                    this.setState({ speedTestOn: !this.state.speedTestOn })
                  }
                  style={{
                    backgroundColor: this.state.speedTestOn ? "#40e0d0" : "",
                    border: "none",
                    borderRadius: 10,
                  }}
                >
                  Speed Test
                </button>
                {/* <button
                  onClick={() => this.renderSheetName(y, z)}
                  style={{
                    border: "none",
                    borderRadius: 10,
                  }}
                >
                  go!
                </button> */}
                <button
                  onClick={() =>
                    this.setState({ nextValue: this.state.nextValue + 1 })
                  }
                  style={{ border: "none", borderRadius: "50%" }}
                >
                  <FontAwesomeIcon icon={faAngleDoubleRight} />
                </button>
                <br />
                <button
                  onClick={() => this.props.history.push("timetable")}
                  style={{
                    border: "none",
                    borderTopLeftRadius: 10,
                    borderBottomLeftRadius: 10,
                  }}
                >
                  Foundation
                </button>
                <button
                  style={{
                    backgroundColor: "#40e0d0",
                    border: "none",
                    borderTopRightRadius: 10,
                    borderBottomRightRadius: 10,
                  }}
                >
                  Advance
                </button>
              </th>
            </tr>
          </tbody>
        </table>
        <table class="table table-hover">
          <thead>
            <th scope="row">Day</th>
            <th scope="row">
              <h5
                style={{
                  textAlign: "center",
                }}
              >
                Lecture 1
              </h5>
            </th>
            <th scope="row">
              <h5
                style={{
                  textAlign: "center",
                }}
              >
                Lecture 2
              </h5>
            </th>
            <th scope="row">
              <h5
                style={{
                  textAlign: "center",
                }}
              >
                Vocab
              </h5>
            </th>
          </thead>
          <tbody>
            <tr>
              <th
                style={{
                  color: this.state.currentDay === "Monday" ? "white" : "",
                }}
                scope="col"
              >
                Monday
              </th>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoSheet(item.ml1sheetm);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoSheet(item.ml1sheetp);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.ml1subject} ({item.ml1topic})
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoSheet(item.ml2sheetm);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoSheet(item.ml2sheetp);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.ml2subject} ({item.ml2topic})
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoVocab("monday", item.mvtopic);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoVocabQuiz("monday", item.mvtopic);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.mvtopic}-M
                </button>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  color: this.state.currentDay === "Tuesday" ? "white" : "",
                }}
                scope="col"
              >
                Tuesday
              </th>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoSheet(item.tul1sheetm);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoSheet(item.tul1sheetp);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.tul1subject} ({item.tul1topic})
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoSheet(item.tul2sheetm);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoSheet(item.tul2sheetp);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.tul2subject} ({item.tul2topic})
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoVocab("tuesday", item.mvtopic);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoVocabQuiz("tuesday", item.mvtopic);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.tuvtopic}-TU
                </button>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  color: this.state.currentDay === "Wednesday" ? "white" : "",
                }}
                scope="col"
              >
                Wednesday
              </th>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoSheet(item.wl1sheetm);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoSheet(item.wl1sheetp);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.wl1subject} ({item.wl1topic})
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoSheet(item.wl2sheetm);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoSheet(item.wl2sheetp);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.wl2subject} ({item.wl2topic})
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoVocab("wednesnday", item.mvtopic);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoVocabQuiz("wednesday", item.mvtopic);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.wvtopic}-W
                </button>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  color: this.state.currentDay === "Thursday" ? "white" : "",
                }}
                scope="col"
              >
                Thursday
              </th>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoSheet(item.thl1sheetm);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoSheet(item.thl1sheetp);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.thl1subject} ({item.thl1topic})
                </button>
                {moduleId === 6 ? (
                  <>
                    <button
                      onClick={() => {
                        if (this.state.practiceSheetOn === false) {
                          this.gotoSheet("F/LR/STATEMENT_AND_CONCLUSIONS/MS");
                        } else if (this.state.practiceSheetOn === true) {
                          this.gotoSheet("F/LR/STATEMENT_AND_CONCLUSIONS/PS");
                        }
                      }}
                      style={{
                        width: "100%",
                        border: "none",
                      }}
                    >
                      {item.thl1subject} (Statement and conclusions)
                    </button>
                    <button
                      onClick={() => {
                        if (this.state.practiceSheetOn === false) {
                          this.gotoSheet("F/LR/STATEMENT_AND_ARGUMENT/MS");
                        } else if (this.state.practiceSheetOn === true) {
                          this.gotoSheet("F/LR/STATEMENT_AND_ARGUMENT/PS");
                        }
                      }}
                      style={{
                        width: "100%",
                        border: "none",
                      }}
                    >
                      {item.thl1subject} (Statement and Arguments)
                    </button>
                  </>
                ) : null}
              </td>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoSheet(item.thl2sheetm);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoQuiz(item.thl2sheetp);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.thl2subject} ({item.thl2topic})
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoVocab("thursday", item.mvtopic);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoVocabQuiz("thursday", item.mvtopic);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.thvtopic}-TH
                </button>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  color: this.state.currentDay === "Friday" ? "white" : "",
                }}
                scope="col"
              >
                Friday
              </th>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoSheet(item.fl1sheetm);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoSheet(item.fl1sheetp);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.fl1subject} ({item.fl1topic})
                </button>
                {moduleId === 6 ? (
                  <>
                    <button
                      onClick={() => {
                        if (this.state.practiceSheetOn === false) {
                          this.gotoSheet("F/LR/PASSAGE_AND_CONCLUSION/MS");
                        } else if (this.state.practiceSheetOn === true) {
                          this.gotoSheet("F/LR/PASSAGE_AND_CONCLUSION/PS");
                        }
                      }}
                      style={{
                        width: "100%",
                        border: "none",
                      }}
                    >
                      {item.fl1subject} (Passage and Conclusion)
                    </button>
                    <button
                      onClick={() => {
                        if (this.state.practiceSheetOn === false) {
                          this.gotoSheet("F/LR/CAUSE_AND_EFFECT/MS");
                        } else if (this.state.practiceSheetOn === true) {
                          this.gotoSheet("F/LR/CAUSE_AND_EFFECT/PS");
                        }
                      }}
                      style={{
                        width: "100%",
                        border: "none",
                      }}
                    >
                      {item.fl1subject} (Cause and Effect)
                    </button>
                  </>
                ) : null}
              </td>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoSheet(item.fl2sheetm);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoQuiz(item.fl2sheetp);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.fl2subject} ({item.fl2topic})
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoVocab("friday", item.mvtopic);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoVocabQuiz("friday", item.mvtopic);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.fvtopic}-F
                </button>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  color: this.state.currentDay === "Saturday" ? "white" : "",
                }}
                scope="col"
              >
                Saturday
              </th>
              <td>
                <button
                  onClick={() => {
                    if (this.state.currentDay === "Saturday") {
                      this.gotoTest(item.testname);
                    } else {
                      alert(`${this.props.newuser} its not saturday`);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  WEEKLY TEST
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                    if (this.state.currentDay === "Saturday") {
                      this.gotoTest(item.testname);
                    } else {
                      alert(`${this.props.newuser} its not saturday`);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.testname}
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoVocab("saturday", item.mvtopic);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoVocabQuiz("saturday", item.mvtopic);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.savtopic}-SA
                </button>
              </td>
            </tr>
            <tr>
              <th
                style={{
                  color: this.state.currentDay === "Sunday" ? "white" : "",
                }}
                scope="col"
              >
                Sunday
              </th>
              <td>
                <button
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  -
                </button>
              </td>
              <td>
                <button
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  -
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                    if (this.state.practiceSheetOn === false) {
                      this.gotoVocab("sunday", item.mvtopic);
                    } else if (this.state.practiceSheetOn === true) {
                      this.gotoVocabQuiz("sunday", item.mvtopic);
                    }
                  }}
                  style={{
                    width: "100%",
                    border: "none",
                  }}
                >
                  {item.suvtopic}-SU
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    ));
  };

  autoUpdate = () => {
    const thisDate = new Date();
    const thisHour = thisDate.getHours();
    const thisMinute = thisDate.getMinutes();
    const timeVaue = thisHour * 100 + thisMinute;
    if (timeVaue > 845 && timeVaue < 1245) {
      this.setState({
        batchName: "BATCH-I",
        isY: true,
        autoUpdate: false,
        testing: timeVaue,
      });
    } else if (timeVaue > 1245 && timeVaue < 1645) {
      this.setState({
        batchName: "BATCH-II",
        isY: false,
        autoUpdate: false,
      });
    } else if (timeVaue > 1645 && timeVaue < 2045) {
      this.setState({
        batchName: "BATCH-III",
        isY: true,
        autoUpdate: false,
      });
    } else {
      this.setState({ autoUpdate: false });
    }
  };

  render() {
    const date1 = new Date("11/22/2021");
    const date2 = new Date();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const newdiffDays = Math.floor(diffDays);
    const diffDaysQ7 = Math.floor(diffDays / 7);
    const diffDaysR8 = diffDaysQ7 % 6;
    const magicValue = diffDaysR8 + 1;
    const x = this.state.nextValue;
    const y = (magicValue + (x % 6)) % 6;
    const z = (y > 3 ? y - 3 : y + 3) % 6;
    const m = new Date();
    m.setDate(m.getDate() - (newdiffDays % 7) + 1 + 7 * x);
    const newDate = m.getDate();
    const newMonth = m.getMonth() + 1;
    const newYear = m.getFullYear();
    const finalDate = newDate + "/" + newMonth + "/" + newYear;
    return (
      <div>
        {this.state.autoUpdate ? this.autoUpdate() : null}
        <button
          className={
            this.state.batchName === "BATCH-I"
              ? "batchbuttonsactive"
              : "batchbuttons"
          }
          onClick={() => this.setState({ batchName: "BATCH-I", isY: true })}
        >
          BATCH-I
          <br />
          10:00 am - 12:00 pm
        </button>
        <button
          className={
            this.state.batchName === "BATCH-II"
              ? "batchbuttonsactive"
              : "batchbuttons"
          }
          onClick={() => this.setState({ batchName: "BATCH-II", isY: false })}
        >
          BATCH-II
          <br />
          2:00 pm - 04:00 pm
        </button>
        <button
          className={
            this.state.batchName === "BATCH-III"
              ? "batchbuttonsactive"
              : "batchbuttons"
          }
          onClick={() => this.setState({ batchName: "BATCH-III", isY: true })}
        >
          BATCH-III
          <br />
          06:00 pm - 08:00 pm
        </button>
        {this.renderTable(y, z, finalDate)}
        {this.state.t1}
        <h1>{this.state.t1}</h1>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newuser: state.currentUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changesheet: (sheet) => {
      dispatch(changeSheet(sheet));
    },
    changeDay: (day) => {
      dispatch(changeDay(day));
    },
    changeWeek: (week) => {
      dispatch(changeWeek(week));
    },
    changeTestPaper: (testpaper) => {
      dispatch(changeTestPaper(testpaper));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeTable);
