const initialState = {
  sheet: "",
  testpaper: "",
  currentUser: "",
  currentUserLevel: "",
  userAnswerList: [],
  userFlagList: [],
  userTestAnswerList: [],
  userTestFlagList: [],
  testTimerList: [],
  day: "",
  week: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "CHANGE_SHEET":
      return { ...state, sheet: action.payload };
    case "CHANGE_TEST_PAPER":
      return { ...state, testpaper: action.payload };
    case "CHANGE_USERNAME":
      return { ...state, currentUser: action.payload };
    case "CHANGE_USER_LEVEL":
      return { ...state, currentUserLevel: action.payload };
    case "CHANGE_USER_ANSWER_LIST":
      return { ...state, userAnswerList: action.payload };
    case "CHANGE_USER_FLAG_LIST":
      return { ...state, userFlagList: action.payload };
    case "CHANGE_USER_TEST_ANSWER_LIST":
      return { ...state, userTestAnswerList: action.payload };
    case "CHANGE_USER_TEST_FLAG_LIST":
      return { ...state, userTestFlagList: action.payload };
    case "CHANGE_TEST_TIMER_LIST":
      return { ...state, testTimerList: action.payload };
    case "CHANGE_DAY":
      return { ...state, day: action.payload };
    case "CHANGE_WEEK":
      return { ...state, week: action.payload };
    default:
      return state;
  }
};

export default reducer;
