import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { Collapse } from "reactstrap";
import ReactPlayer from "react-player";
import "./TopicSheet.css";
import Calculator from "../components/calculator/Calculator";
import Calculator1 from "../components/Calculator";
import VideoCallFrame from "./VideoCallFrame";
import {
  changeUserAnswerList,
  changeUserFlagList,
  changeSheet,
} from "../actions/action";

class TopicSheet extends React.Component {
  constructor(props) {
    super(props);
    let loggedIn = false;

    const token = localStorage.getItem("token");
    if (token) loggedIn = true;
    this.state = {
      loggedIn,
      questionList: [],
      currentQuestion: { question: "loading...", hint: "loading..." },
      isConceptOpen: false,
      isLiveClassOpen: false,
      isConceptVideoOpen: false,
      isHintOpen: false,
      isHintVideoOpen: false,
      submitSheet: false,
      userAnswerList: [],
      userFlagList: [],
      answeredId: "",
      submitAnswerOn: true,
      wordDetails: [],
      word: "",
      meaning: "",
      sheetList: [],
      needHelp: false,
      password: "",
      showClearButton: true,
      isMainSheet:
        this.props.newsheet.sheet_name.slice(-2) === "MS" ? true : false,
    };
  }
  componentDidMount() {
    this.List();
    this.getUserAnswers();
    this.getUserFlags();
    this.sheetList();
    this.updateOfflineData();
  }

  renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      this.setState({ needHelp: false });
    }

    return (
      <div
        style={{
          fontFamily: "Montserrat",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ color: "#aaa" }}>helping! within</div>
        <div style={{ fontSize: 40 }}>{remainingTime}</div>
        <div style={{ color: "#aaa" }}>seconds</div>
      </div>
    );
  };

  updateOfflineData = () => {
    const answerList = this.props.useranswerlist || [];
    const flagList = this.props.userflaglist || [];
    if (answerList.length > 0) {
      answerList.forEach((item) =>
        axios
          .post(`https://backend.mccubeindia.com/sheet/useranswers/`, item, {
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          })
          .then(() => this.props.changeuseranswerlist([""]))
          .then(() => this.getUserAnswers())
      );
    }
    if (flagList.length > 0) {
      flagList.forEach((item) =>
        axios
          .post(`https://backend.mccubeindia.com/sheet/flag/`, item, {
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          })
          .then(() => this.props.changeuserflaglist([]))
          .then((res) => this.getUserFlags())
      );
    }
  };

  sheetList = () => {
    axios
      .get("https://backend.mccubeindia.com/sheet/sheetname/", {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ sheetList: res.data }));
  };
  gotoSheet = () => {
    const sheet = this.props.newsheet.sheet_name;
    const isMainSheet =
      this.props.newsheet.sheet_name.slice(-2) === "MS" ? true : false;
    if (isMainSheet) {
      const newSheet = sheet.slice(0, -2) + "PS";

      this.state.sheetList
        .filter((item) => item.sheet_name === newSheet)
        .map((item) => this.props.changesheet(item));
      window.location.reload();
    } else {
      const newSheet = sheet.slice(0, -2) + "MS";

      this.state.sheetList
        .filter((item) => item.sheet_name === newSheet)
        .map((item) => this.props.changesheet(item));
      window.location.reload();
    }
  };

  needHelp = () => {
    this.setState({ needHelp: true });
    const item = {
      user_name: this.props.newuser,
      sheet_name: "help",
    };
    axios.post(`https://backend.mccubeindia.com/user/activeuser/`, item, {
      headers: {
        Authorization: `JWT ${localStorage.getItem("token")}`,
      },
    });
  };
  wordToMeaning = (x) => {
    const word = x.replace(/[^a-zA-Z0-9]/g, "");
    axios
      .get(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`)
      .then((res) => this.setState({ wordDetails: res.data }))
      .then(() =>
        this.setState({
          word: x,
          meaning:
            this.state.wordDetails[0].meanings[0].definitions[0].definition,
        })
      )
      .then(() => alert(`${this.state.word} means ${this.state.meaning}`));
  };

  conceptToggle = () =>
    this.setState({ isConceptOpen: !this.state.isConceptOpen });
  conceptVideoToggle = () =>
    this.setState({ isConceptVideoOpen: !this.state.isConceptVideoOpen });
  liveClassToggle = () => {
    this.setState({ isLiveClassOpen: !this.state.isLiveClassOpen });
  };
  hintToggle = () => this.setState({ isHintOpen: !this.state.isHintOpen });
  hintVideoToggle = () =>
    this.setState({ isHintVideoOpen: !this.state.isHintVideoOpen });

  List = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/sheet/questions/?select_sheet_name=${this.props.newsheet.id}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => this.setState({ questionList: res.data }))
      .then(() => this.startSheet());
  };

  getUserAnswers = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/sheet/useranswers/?user_name=${this.props.newuser}&sheet_name=${this.props.newsheet.sheet_name}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => this.setState({ userAnswerList: res.data }))
      .then(() => this.updateOfflineData());
  };

  getUserFlags = () => {
    axios
      .get(
        `https://backend.mccubeindia.com/sheet/flag/?user_name=${this.props.newuser}&sheet_name=${this.props.newsheet.sheet_name}`,
        {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => this.setState({ userFlagList: res.data }))
      .then(() => this.updateOfflineData());
  };

  refreshSheet = () => {
    this.List();
    this.getUserAnswers();
    this.getUserFlags();
    this.setState({ needHelp: false });
  };

  delAns = (x) => {
    axios
      .delete(`https://backend.mccubeindia.com/sheet/useranswers/${x}/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then(() =>
        this.setState({
          userAnswerList: this.state.userAnswerList.filter(
            (item) => item.id !== x
          ),
        })
      );
  };

  removeAllAnswers = () => {
    const newitem = this.state.userAnswerList.map((item) => item.id);
    newitem.forEach((item) => {
      this.delAns(item);
    });
  };

  removeIncorrectAnswers = () => {
    const newitem = this.state.userAnswerList
      .filter((item) => item.user_answer !== item.correct_answer)
      .map((item) => item.id);
    newitem.forEach((item) => {
      this.delAns(item);
    });
  };

  submitAnswer = (selectedOption) => {
    if (this.state.submitAnswerOn === true) {
      this.setState({ submitAnswerOn: false });
      const answered = this.state.userAnswerList.filter(
        (item) => item.question_no === this.state.currentQuestion.question_no
      );
      if (answered.length === 0) {
        const item = {
          user_name: this.props.newuser,
          sheet_name: this.state.currentQuestion.sheet_name,
          question_no: this.state.currentQuestion.question_no,
          user_answer: selectedOption,
          correct_answer: this.state.currentQuestion.correct_answer,
        };
        this.setState({ userAnswerList: [...this.state.userAnswerList, item] });
        axios
          .post(`https://backend.mccubeindia.com/sheet/useranswers/`, item, {
            headers: {
              Authorization: `JWT ${localStorage.getItem("token")}`,
            },
          })
          .then(() => this.setState({ submitAnswerOn: true }))
          .then(() => this.getUserAnswers())
          .catch(() => {
            const offlineList = this.props.useranswerlist || [];
            const newofflineList = [...offlineList, item];
            this.props.changeuseranswerlist(newofflineList);
          });
      } else {
        this.setState({ submitAnswerOn: true });
        alert("you cannot change your answer");
      }
    } else {
      this.setState({ submitAnswerOn: true });
    }
  };

  submitFlag = () => {
    const answered = this.state.userFlagList.filter(
      (item) => item.question_no === this.state.currentQuestion.question_no
    );
    if (answered.length === 0) {
      const item = {
        user_name: this.props.newuser,
        sheet_name: this.state.currentQuestion.sheet_name,
        question_no: this.state.currentQuestion.question_no,
      };
      this.setState({ userFlagList: [...this.state.userFlagList, item] });
      axios
        .post(`https://backend.mccubeindia.com/sheet/flag/`, item, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => this.getUserFlags())
        .catch(() => {
          const offlineList = this.props.userflaglist || [];
          const newofflineList = [...offlineList, item];
          this.props.changeuserflaglist(newofflineList);
        });
    } else {
      const ansId = answered[0].id;
      const quesionNumber = answered[0].question_no;
      this.setState({
        userFlagList: this.state.userFlagList.filter(
          (item) => item.id !== ansId
        ),
      });
      axios
        .delete(`https://backend.mccubeindia.com/sheet/flag/${ansId}/`, {
          headers: {
            Authorization: `JWT ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => this.getUserFlags())
        .catch(() => {
          const offlineList = this.props.userflaglist || [];
          const newofflineList = offlineList.filter(
            (item) => item.question_no !== quesionNumber
          );
          this.props.changeuserflaglist(newofflineList);
        });
    }
  };

  questionNumberList = () => {
    const correctAnswerList = this.state.userAnswerList.filter(
      (item) => item.user_answer === item.correct_answer
    );
    const incorrectAnswerList = this.state.userAnswerList.filter(
      (item) => item.user_answer !== item.correct_answer
    );
    const correctAnswered = correctAnswerList.map((item) => item.question_no);
    const incorrectAnswered = incorrectAnswerList.map(
      (item) => item.question_no
    );
    const flag = this.state.userFlagList.map((item) => item.question_no);
    const newItems = this.state.questionList;
    return newItems.map((item) => (
      <button
        className={
          flag.includes(item.question_no)
            ? correctAnswered.includes(item.question_no)
              ? "button-s correct_question-flag"
              : incorrectAnswered.includes(item.question_no)
              ? "button-s incorrect_question-flag"
              : "button-s normal_question-flag"
            : correctAnswered.includes(item.question_no)
            ? "button-s correct_question"
            : incorrectAnswered.includes(item.question_no)
            ? "button-s incorrect_question"
            : "button-s normal_question"
        }
        onClick={() => this.selectQuestion(item)}
      >
        {item.question_no}
      </button>
    ));
  };

  startSheet = () => {
    const newItems = this.state.questionList.filter(
      (item) => item.question_no === 1
    );
    return newItems.map((item) => this.setState({ currentQuestion: item }));
  };

  previous = (current) => {
    const previous = current - 1;
    const newItems = this.state.questionList.filter(
      (item) => item.question_no === previous
    );
    return newItems.map((item) => (
      <button className="button2" onClick={() => this.selectQuestion(item)}>
        previous
      </button>
    ));
  };

  next = (current) => {
    const next = current + 1;
    const newItems = this.state.questionList.filter(
      (item) => item.question_no === next
    );
    return newItems.map((item) => (
      <button className="button2" onClick={() => this.selectQuestion(item)}>
        next
      </button>
    ));
  };

  selectQuestion = (item) => {
    this.setState({
      isConceptOpen: false,
      isConceptVideoOpen: false,
      isHintOpen: false,
      isHintVideoOpen: false,
      currentQuestion: item,
      subitAnswerOn: true,
    });
  };

  submitSheet = () => {
    const item = {
      user_name: this.props.newuser,
      sheet_name: this.state.currentQuestion.sheet_name,
    };
    axios
      .post(`https://backend.mccubeindia.com/sheet/index/`, item, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.props.history.goBack())
      .catch(() => {
        this.props.history.push("logout");
      });
  };

  closeSheet = () => this.setState({ submitSheet: true });

  class_name = (x) => {
    const answered = this.state.userAnswerList.filter(
      (item) => item.question_no === this.state.currentQuestion.question_no
    );
    if (answered.length === 0) {
      return "list-group-item list-group-item-action normal_option";
    } else if (x === answered[0].correct_answer) {
      return "list-group-item list-group-item-action correct_option";
    } else if (x === answered[0].user_answer) {
      return "list-group-item list-group-item-action incorrect_option";
    } else {
      return "list-group-item list-group-item-action normal_option";
    }
  };
  password = () => {
    const d = new Date();
    let minutes = d.getMinutes();
    var word = this.props.newuser;
    var sum = 0;
    word
      .toUpperCase()
      .split("")
      .forEach(function (alphabet) {
        sum += alphabet.charCodeAt(0) - 64;
      });
    var password1 = (sum + minutes) * (sum + minutes);
    var password2 = (sum + minutes + 1) * (sum + minutes + 1);
    const otp = window.prompt("enter otp");
    if (otp == password1 || otp == password2) {
      this.removeIncorrectAnswers();
      this.setState({ showClearButton: false });
    } else {
      alert("incorrect otp");
    }
    setTimeout(() => {
      this.setState({ showClearButton: true });
    }, 180000);
  };
  render() {
    if (this.state.loggedIn === false) {
      return <Redirect to="/logout" />;
    } else if (this.state.submitSheet) {
      return <Redirect to="/home" />;
    }
    return (
      <div className="topicsheet">
        <div>{this.props.newsheet.sheet_name}</div>
        <div className="concept">
          <button className="button2" onClick={() => this.refreshSheet()}>
            refresh
          </button>

          <button className="button2" onClick={() => this.conceptToggle()}>
            concept
          </button>
          <Collapse isOpen={this.state.isConceptOpen}>
            <div>{this.props.newsheet.concept}</div>
            <img src={this.props.newsheet.concept_image} alt="" />
          </Collapse>
        </div>
        <div className="concept_video">
          <button className="button2" onClick={() => this.conceptVideoToggle()}>
            concept video
          </button>
          <Collapse isOpen={this.state.isConceptVideoOpen}>
            <div className="wrapper">
              <ReactPlayer
                controls
                url={this.props.newsheet.concept_video}
                width="100%"
                height="100%"
                className="player"
              />
            </div>
          </Collapse>
        </div>
        <button className="button2" onClick={() => this.liveClassToggle()}>
          Live Class
        </button>
        <button
          className="button2"
          onClick={() => {
            if (this.state.userAnswerList.length === 0) {
              this.props.history.push("quiz");
            } else {
              alert("first clesr your sheet");
            }
          }}
        >
          Speed Test
        </button>
        <button
          className="button2"
          onClick={() => {
            this.gotoSheet();
          }}
        >
          {this.state.isMainSheet ? "practice" : "main"}
        </button>
        {this.state.isLiveClassOpen ? (
          <div className="videoFameDiv">
            <VideoCallFrame
              url={"https://mccube.daily.co/meet-cat"}
            ></VideoCallFrame>
          </div>
        ) : null}

        <div>{this.questionNumberList()}</div>
        <button className="button2" onClick={() => this.submitFlag()}>
          Flag
        </button>
        <div class="input-group input-group-lg">
          <div class="input-group-prepend">
            <span class="input-group-text" id="inputGroup-sizing-lg">
              Question. {this.state.currentQuestion.question_no}
            </span>
          </div>
          <div className="question">
            {this.state.currentQuestion.question.split("<br>").map((i) => (
              <>
                <p>
                  {i.split(" ").map((item) => (
                    <div
                      style={{ paddingRight: 5, minWidth: 10, float: "left" }}
                      onClick={() => this.wordToMeaning(item)}
                    >
                      {item}
                    </div>
                  ))}
                </p>
                <br />
                <br />
              </>
            ))}
            {this.state.currentQuestion.question_image === "1" ? null : this
                .state.currentQuestion.question_image === "" ? null : (
              <img
                src={this.state.currentQuestion.question_image}
                alt=""
                className="qimage"
              />
            )}
          </div>
        </div>
        <div>
          <div class="list-group">
            {this.state.currentQuestion.blank_type ? (
              <button type="button" className={this.class_name("a")}>
                {this.state.currentQuestion.option_blank}
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  className={this.class_name("a")}
                  onDoubleClick={() => this.submitAnswer("a")}
                >
                  (A) {this.state.currentQuestion.option_a}
                </button>
                <button
                  type="button"
                  className={this.class_name("b")}
                  onDoubleClick={() => this.submitAnswer("b")}
                >
                  (B) {this.state.currentQuestion.option_b}
                </button>
                <button
                  type="button"
                  className={this.class_name("c")}
                  onDoubleClick={() => this.submitAnswer("c")}
                >
                  (C) {this.state.currentQuestion.option_c}
                </button>
                <button
                  type="button"
                  className={this.class_name("d")}
                  onDoubleClick={() => this.submitAnswer("d")}
                >
                  (D) {this.state.currentQuestion.option_d}
                </button>
              </div>
            )}

            <div className="bottombuttons">
              <div>{this.previous(this.state.currentQuestion.question_no)}</div>
              <div className="hint">
                <button className="button2" onClick={() => this.hintToggle()}>
                  hint
                </button>
                <Collapse isOpen={this.state.isHintOpen}>
                  {this.state.currentQuestion.hint.split("<br>").map((i) => (
                    <p>{i}</p>
                  ))}
                  {this.state.currentQuestion.hint_image === "1" ? null : this
                      .state.currentQuestion.hint_image === "" ? null : (
                    <img
                      src={this.state.currentQuestion.hint_image}
                      alt=""
                      className="qimage"
                    />
                  )}
                </Collapse>
              </div>
              <div className="hint_video">
                <button
                  className="button2"
                  onClick={() => this.hintVideoToggle()}
                >
                  hint video
                </button>
              </div>
              <div>{this.next(this.state.currentQuestion.question_no)}</div>
            </div>
          </div>
          <button
            className="button2"
            onClick={() => {
              if (window.confirm("are you sure")) this.removeAllAnswers();
            }}
          >
            Clear
          </button>
          {this.state.showClearButton ? (
            <button
              className="button2"
              onClick={() => {
                this.password();
              }}
            >
              Clear Incorrect
            </button>
          ) : null}

          <button className="button2" onClick={() => this.submitSheet()}>
            Submit Sheet
          </button>
          <button className="button2" onClick={() => this.closeSheet()}>
            Close Sheet
          </button>
          <Collapse isOpen={this.state.isHintVideoOpen}>
            <div className="wrapper">
              <ReactPlayer
                controls
                url={this.state.currentQuestion.hint_video}
                width="100%"
                height="100%"
                className="player"
              />
            </div>
          </Collapse>
        </div>
        {this.props.newsheet.allow_calc ? (
          <>
            <button
              className="button2"
              onClick={() =>
                this.setState({
                  isCalcOpen: !this.state.isCalcOpen,
                  isCalc1Open: false,
                })
              }
            >
              Calculator_1
            </button>
            <button
              className="button2"
              onClick={() =>
                this.setState({
                  isCalc1Open: !this.state.isCalc1Open,
                  isCalcOpen: false,
                })
              }
            >
              Calculator_2
            </button>
          </>
        ) : null}
        {this.state.isCalcOpen ? <Calculator /> : null}
        {this.state.isCalc1Open ? <Calculator1 /> : null}
        {!this.state.needHelp ? (
          <button className="button2" onClick={() => this.needHelp()}>
            Help!
          </button>
        ) : (
          <button className="button2">Wait!</button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newsheet: state.sheet,
    newuser: state.currentUser,
    useranswerlist: state.userAnswerList,
    userflaglist: state.userFlagList,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    changeuseranswerlist: (userAnswerList) => {
      dispatch(changeUserAnswerList(userAnswerList));
    },
    changeuserflaglist: (userFlagList) => {
      dispatch(changeUserFlagList(userFlagList));
    },
    changesheet: (sheet) => {
      dispatch(changeSheet(sheet));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TopicSheet);
