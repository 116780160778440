import React from "react";
import "./Login.css";
export default class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      currentUserName: "",
      username: "",
      password: "",
      loggedIn: localStorage.getItem("token") ? true : false,
    };
    this.onChange = this.onChange.bind(this);
  }

  handle_login = (e) => {
    e.preventDefault();
    let data = {
      username: this.state.username,
      password: this.state.password,
    };

    let handleErrors = (response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response;
    };

    fetch("https://backend.mccubeindia.com/login/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(handleErrors)
      .then((res) => res.json())
      .then((json) => {
        localStorage.setItem("token", json.token);
        this.setState({ currentUserName: json.user.username, loggedIn: true });
      })
      .then(() => {
        this.props.history.push("home");
      })
      .catch((error) => alert(error));
  };

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    return (
      <div className="lbody">
        <div className="lcontainer">
          <form onSubmit={this.handle_login}>
            <input
              type="text"
              className="form-control"
              placeholder="username"
              value={this.state.username}
              onChange={this.onChange}
              name="username"
            />
            <input
              type="password"
              className="form-control"
              placeholder="password"
              value={this.state.password}
              onChange={this.onChange}
              name="password"
            />
            <button type="submit"> Login</button>
          </form>
        </div>
      </div>
    );
  }
}
