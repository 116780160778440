export const changeSheet = (sheet) => {
  return {
    type: "CHANGE_SHEET",
    payload: sheet,
  };
};

export const changeTestPaper = (testpaper) => {
  return {
    type: "CHANGE_TEST_PAPER",
    payload: testpaper,
  };
};

export const changeCurrentUser = (currentUser) => {
  return {
    type: "CHANGE_USERNAME",
    payload: currentUser,
  };
};

export const changeCurrentUserLevel = (currentUserLevel) => {
  return {
    type: "CHANGE_USER_LEVEL",
    payload: currentUserLevel,
  };
};

export const changeUserAnswerList = (userAnswerList) => {
  return {
    type: "CHANGE_USER_ANSWER_LIST",
    payload: userAnswerList,
  };
};

export const changeUserFlagList = (userFlagList) => {
  return {
    type: "CHANGE_USER_FLAG_LIST",
    payload: userFlagList,
  };
};

export const changeUserTestAnswerList = (userTestAnswerList) => {
  return {
    type: "CHANGE_USER_TEST_ANSWER_LIST",
    payload: userTestAnswerList,
  };
};

export const changeUserTestFlagList = (userTestFlagList) => {
  return {
    type: "CHANGE_USER_TEST_FLAG_LIST",
    payload: userTestFlagList,
  };
};

export const changeTestTimerList = (testTimerList) => {
  return {
    type: "CHANGE_TEST_TIMER_LIST",
    payload: testTimerList,
  };
};

export const changeDay = (day) => {
  return {
    type: "CHANGE_DAY",
    payload: day,
  };
};

export const changeWeek = (week) => {
  return {
    type: "CHANGE_WEEK",
    payload: week,
  };
};
