import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { NavLink } from "react-router-dom";

const CustomNavbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);
  const dtoggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <div>
      <Navbar className="navbar" light expand="md">
        <NavbarBrand style={{ color: "whitesmoke" }} href="/">
          MCCUBE
        </NavbarBrand>
        <NavbarToggler
          style={{ backgroundColor: "whitesmoke" }}
          onClick={toggle}
        />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink
                to={"/syllabus"}
                className="nav-link"
                activeClassName="nav-link--active"
                onClick={toggle}
                style={{ color: "whitesmoke" }}
              >
                Syllabus
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={"/recenttopresults"}
                className="nav-link"
                activeClassName="nav-link--active"
                onClick={toggle}
                style={{ color: "whitesmoke" }}
              >
                Top Results
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={"/topcolleges"}
                className="nav-link"
                activeClassName="nav-link--active"
                onClick={toggle}
                style={{ color: "whitesmoke" }}
              >
                Top Colleges
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={"/timetable"}
                className="nav-link"
                activeClassName="nav-link--active"
                onClick={toggle}
                style={{ color: "whitesmoke" }}
              >
                Time Table
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={"/topicfilter"}
                className="nav-link"
                activeClassName="nav-link--active"
                onClick={toggle}
                style={{ color: "whitesmoke" }}
              >
                Topic Sheets
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={"/completedsheets"}
                className="nav-link"
                activeClassName="nav-link--active"
                onClick={toggle}
                style={{ color: "whitesmoke" }}
              >
                Completed Sheets
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={"/testfilter"}
                className="nav-link"
                activeClassName="nav-link--active"
                onClick={toggle}
                style={{ color: "whitesmoke" }}
              >
                Test Papers
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to={"/completedtestpapers"}
                className="nav-link"
                activeClassName="nav-link--active"
                onClick={toggle}
                style={{ color: "whitesmoke" }}
              >
                Completed Tests
              </NavLink>
            </NavItem>
            <Dropdown isOpen={dropdownOpen} toggle={dtoggle}>
              <DropdownToggle caret>Daily Tasks</DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <NavLink
                    to={"/vocab"}
                    className="nav-link"
                    activeClassName="nav-link--active"
                    onClick={toggle}
                  >
                    Daily Vocab Sheet
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    to={"/currentaffair"}
                    className="nav-link"
                    activeClassName="nav-link--active"
                    onClick={toggle}
                  >
                    Daily Current Affairs
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    to={"/thehinduarticle"}
                    className="nav-link"
                    activeClassName="nav-link--active"
                    onClick={toggle}
                  >
                    The Hindu Articles
                  </NavLink>
                </DropdownItem>
                <DropdownItem>
                  <NavLink
                    to={"/sudoku"}
                    className="nav-link"
                    activeClassName="nav-link--active"
                    onClick={toggle}
                  >
                    Daily Sudoku Puzzle
                  </NavLink>
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
            <NavItem>
              <NavLink
                to={"/logout"}
                className="nav-link"
                activeClassName="nav-link--active"
                onClick={toggle}
                style={{ color: "whitesmoke" }}
              >
                Logout
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default CustomNavbar;
