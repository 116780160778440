import React, { Component } from "react";
import "./Login.css";
import a1 from "./testimonials/01.jpg";
import a2 from "./testimonials/02.jpg";
import a3 from "./testimonials/03.jpg";
import a4 from "./testimonials/04.jpg";
import a5 from "./testimonials/05.jpg";
import a6 from "./testimonials/06.jpg";
import a7 from "./testimonials/07.jpg";
import a8 from "./testimonials/08.jpg";
import a9 from "./testimonials/09.jpg";
import a10 from "./testimonials/10.jpg";
import a11 from "./testimonials/11.jpg";
import a12 from "./testimonials/12.jpg";

export default class Results extends Component {
  render() {
    return (
      <div class="testimonial">
        <div class="container">
          <div class="testimonial-item">
            <img src={a1} alt="" />
            <h3>CAT- 99 %le</h3>
            <h3>IIM Rohtak</h3>
            <h2>Abhinav Mishra</h2>
          </div>
          <div class="testimonial-item">
            <img src={a2} alt="" />
            <h3>CAT- 99 %le</h3>
            <h3>XIMB</h3>
            <h2>Alfiya Khan</h2>
          </div>
          <div class="testimonial-item">
            <img src={a3} alt="" />
            <h3>CAT- 93 %le</h3>
            <h3>GIM</h3>
            <h2>Kshitij Mishra</h2>
          </div>
          <div class="testimonial-item">
            <img src={a4} alt="" />
            <h3>CAT- 90 %le</h3>
            <h3>-</h3>
            <h2>Noshin Mev</h2>
          </div>
          <div class="testimonial-item">
            <img src={a5} alt="" />
            <h3>CMAT- 96 %le</h3>
            <h3>GIM</h3>
            <h2>Maneet Gurudatta</h2>
          </div>
          <div class="testimonial-item">
            <img src={a6} alt="" />
            <h3>CMAT- 90 %le</h3>
            <h3>GIM</h3>
            <h2>Sapan Maheshwari</h2>
          </div>
          <div class="testimonial-item">
            <img src={a7} alt="" />
            <h3>CMAT- 90 %le</h3>
            <h3>GIM</h3>
            <h2>Avni Trivedi</h2>
          </div>
          <div class="testimonial-item">
            <img src={a8} alt="" />
            <h3>CMAT- 90 %le</h3>
            <h3>-</h3>
            <h2>Shourya Kesharwani</h2>
          </div>
          <div class="testimonial-item">
            <img src={a9} alt="" />
            <h3>SNAP- 94 %le</h3>
            <h3>SIIB</h3>
            <h2>Paridhi Mahendra</h2>
          </div>
          <div class="testimonial-item">
            <img src={a10} alt="" />
            <h3>SNAP- 90%le</h3>
            <h3>SIMC</h3>
            <h2>Aishwarya Wadnerker</h2>
          </div>
          <div class="testimonial-item">
            <img src={a11} alt="" />
            <h3>CMAT- 90 %le</h3>
            <h3>IMS</h3>
            <h2>Pallavi Jain</h2>
          </div>
          <div class="testimonial-item">
            <img src={a12} alt="" />
            <h3>NMAT- 234</h3>
            <h3>NMIMS</h3>
            <h2>Akash Chouksey</h2>
          </div>
        </div>
      </div>
    );
  }
}
