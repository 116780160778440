import React, { Component } from "react";
import axios from "axios";
import "./Login.css";

export default class Colleges extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collegeList: [],
    };
  }
  componentDidMount() {
    this.getData();
  }
  getData = () => {
    axios
      .get(`https://backend.mccubeindia.com/user/topcolleges/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => this.setState({ collegeList: res.data }))
      .catch(() => {
        this.props.history.push("logout");
      });
  };
  render() {
    const newItem = this.state.collegeList;
    return (
      <div>
        <table class="table table-hover">
          <thead>
            <th scope="row">Rank</th>
            <th scope="row">Name</th>
            <th scope="row">Exam</th>
            <th scope="row">Fees</th>
            <th scope="row">Average Package</th>
            <th scope="row">Location</th>
          </thead>
          <tbody>
            {newItem.map((item) => (
              <tr>
                <td>{item.rank}</td>
                <td>{item.name}</td>
                <td>{item.exam}</td>
                <td>{item.fees}</td>
                <td>{item.avgpkg}</td>
                <td>{item.location}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
